import { Box, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ResponsiveLine } from "@nivo/line";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import store from "../../store";
import { contentText, olive } from "../../theme";
import PageSubSection from "../PageSubSection";
import FractionValue from "./FractionValue";
import GraphLegendItem from "./GraphLegendItem";
import ImportantValue from "./ImportantValue";
import ScaleValue from "./ScaleValue";

type Props = { timeseriesIdx: number };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: contentText,
    selectedEvent: {
      position: "absolute",
      top: theme.spacing(11),
    },
    graphLegendContainer: {
      margin: theme.spacing(1, 0, 4),
    },
  }),
);

function KeyResults({ timeseriesIdx }: Props) {
  const classes = useStyles();
  const {
    translations: { incrementalImpactReport: translations },
  } = useContext(I18nContext);
  const i18n = useContext(I18nContext);

  const summary = (store.impactData.reportData?.summary || [])[timeseriesIdx];
  const dataSeries = store.impactData.reportData?.dataSeries;

  if (!summary || !dataSeries) return null;

  const dataItem = dataSeries[timeseriesIdx];
  const graphLine = { id: "result", data: dataItem.data.cumulative.data };

  const selectedEvents = store.impactData.selectedAudienceEventNames()?.join(", ");

  return (
    <>
      <Typography variant="h6" className={classes.selectedEvent}>
        {selectedEvents}
      </Typography>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item sm={4} xs={12}>
          <Typography variant="caption" className={classes.description}>
            {translations.sections.keyResults.description}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={6}>
          <ImportantValue
            title={translations.subSections.incremental_events}
            value={i18n.formatNumber(summary.incremental_events.mean)}
          />
        </Grid>

        <Grid item sm={4} xs={6}>
          <ImportantValue
            title={translations.subSections.relative_effect}
            value={i18n.formatNumber(summary.relative_effect.mean, "percentWithTwoDecimalDigits")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box style={{ height: 300 }} mb={3}>
            <ResponsiveLine
              colors={olive.main}
              data={[graphLine]}
              margin={{ bottom: 20, left: 60 }}
              curve="monotoneX"
              xScale={{
                type: "time",
                format: "%Y-%m-%d %I:%M:%S",
                useUTC: true,
                precision: "hour",
              }}
              xFormat="time:%Y-%m-%d"
              yScale={{ type: "linear", stacked: true, ...dataItem.data.cumulative.yLimits }}
              axisLeft={{
                legendOffset: -50,
                tickValues: 3,
                legend: translations.legends.installs,
                legendPosition: "middle",
              }}
              enablePoints={false}
              axisBottom={{
                format: "%b %d",
                tickValues: "every 1 days",
                legendOffset: -12,
              }}
              enableSlices="x"
              enableGridX={false}
              gridYValues={3}
              lineWidth={4}
            />
          </Box>
          <Grid className={classes.graphLegendContainer} container spacing={2}>
            <GraphLegendItem variant="solidOliveLine">
              {translations.sections.keyResults.graph.legend.incremental}
            </GraphLegendItem>
            <GraphLegendItem variant="dashedLine">
              {translations.sections.keyResults.graph.legend.organic}
            </GraphLegendItem>
          </Grid>
        </Grid>
      </Grid>
      <PageSubSection
        title={translations.sections.costPerInc.title}
        description={translations.sections.costPerInc.description}
      >
        <Grid container direction="column" alignContent="center">
          <FractionValue
            mainValue={`€ ${i18n.formatNumber(parseFloat(summary.advertiser_cost_per_inc.mean.toString()), "money")}`}
            topLabel={translations.legends.ad_spend}
            bottomLabel={translations.subSections.incremental_events}
          />
        </Grid>
      </PageSubSection>
      <PageSubSection
        title={translations.sections.confidenceRate.title}
        description={translations.sections.confidenceRate.description}
      >
        <Grid container direction="column" alignContent="center">
          <ScaleValue
            value={parseInt(i18n.formatNumber(summary.greater_than_zero_percent, "percentWithTwoDecimalDigits"))}
          />
        </Grid>
      </PageSubSection>
    </>
  );
}

export default observer(KeyResults);
