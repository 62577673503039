import locale from "locale2";
import React from "react";
import I18n from "shared/utilities/i18n";
import en from "../locales/en";

export const i18n = new I18n({ locale, fallbackLocale: "en", languages: { en } });

export type TI18n = typeof i18n;

const I18nContext = React.createContext(i18n);
const { Provider: I18nProvider, Consumer: I18nConsumer } = I18nContext;
export interface TranslationProps {
  i18n: TI18n;
}

const withTranslations = <P extends TranslationProps>(
  WrappedComponent: React.ComponentType<P>,
): React.ComponentType<Omit<P, keyof TranslationProps>> => {
  return (props: Omit<P, keyof TranslationProps>) => {
    return <I18nConsumer>{i18n => <WrappedComponent i18n={i18n} {...(props as any)} />}</I18nConsumer>;
  };
};

export { I18nConsumer, I18nContext, I18nProvider, withTranslations };
