import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { SliceTooltipProps } from "@nivo/line";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import { black, white } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: white,
      padding: theme.spacing(2, 4),
      border: `1px solid ${black}`,
      width: 180,
    },
    point: {
      padding: theme.spacing(1, 0),
      marginLeft: theme.spacing(2),
    },
  }),
);

const POINT_KEYS = ["yFormatted", "lower", "upper"];

const formatValue = (value: number, NAText: string) => (isNaN(value) ? NAText : Math.round(value));

function ImpactGraphTooltip({ slice }: SliceTooltipProps) {
  const i18n = useContext(I18nContext);
  const {
    formatDateTime,
    translations: { graph: translationForGraph, notApplicable },
  } = i18n;
  const sliceId = slice.points.length > 0 ? formatDateTime(slice.points[0].data.x as Date) : "";
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div>{sliceId}</div>
      <div>
        {slice.points.map(point => (
          <ul key={point.id} className={classes.point}>
            {POINT_KEYS.map(pointKey =>
              // @ts-expect-error
              point.data[pointKey] ? (
                <li key={pointKey}>
                  {/* @ts-expect-error */}
                  {`${translationForGraph.tooltip[point.serieId]}${pointKey === "yFormatted" ? "" : ` - ${pointKey}`}`}:
                  {/* @ts-expect-error */}
                  {formatValue(point.data[pointKey] as number, notApplicable)}
                </li>
              ) : null,
            )}
          </ul>
        ))}
      </div>
    </div>
  );
}

export default ImpactGraphTooltip;
