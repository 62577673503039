import { OrganizationsShowResource } from "shared/http/apiTypes";
import { OrganizationSpecificEventData } from "shared/services/TrackingService";

export const createOrganizationSpecificEventData = ({
  id,
  attributes: { name, client_tier },
}: OrganizationsShowResource): OrganizationSpecificEventData => ({
  Organization: {
    ID: id,
    Name: name,
    Tier: client_tier,
  },
});
