import { Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import objectiveIllustration from "../../images/objective-illustration.png";
import { contentText } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: contentText,
    textBox: {
      marginBottom: theme.spacing(4),
    },
  }),
);

function OurObjective() {
  const {
    translations: { incrementalImpactReport: translations },
  } = useContext(I18nContext);
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={8} md={7} className={classes.content}>
          {translations.sections.objective.description}
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <img src={objectiveIllustration} alt={translations.footerImgAlt} />
        </Grid>
      </Grid>
    </>
  );
}

export default OurObjective;
