import flatMap from "lodash/flatMap";
import { ICollectionDocument, PersistedResourceObject } from "./jsonApi";

const DEFAULT_BATCH_SIZE = 100;

export default async function fetchInBatches<Value, Resource extends PersistedResourceObject<any, any>>(
  list: Value[],
  fetchBatch: (batch: Value[]) => Promise<ICollectionDocument<Resource>>,
  { batchSize }: { batchSize: number } = { batchSize: DEFAULT_BATCH_SIZE },
): Promise<ICollectionDocument<Resource>> {
  const requests: Promise<ICollectionDocument<Resource>>[] = [];

  for (let index = 0; index < list.length; index += batchSize) {
    const batch = list.slice(index, index + batchSize);
    requests.push(fetchBatch(batch));
  }

  const responses = await Promise.all(requests);

  const resources = flatMap(responses, response => response.data);

  return { data: resources };
}
