import { createTheme } from "@mui/material/styles";

export const DEFAULT_SPACING = 2;
export const SPACING_BASE_PIXELS = 8;

export const white = "#fff";
export const black = "#1d1d1f";
export const green = "#54665a";
export const yellow = "#edff53";
export const lightBlue = "#e0e4ef";
export const lightGrey = "#f6f4f2";
export const darkGrey = "#8F8F8F";
export const veryDarkGrey = "#8D8D8D";

export const violet = {
  light: "#dbd3ff",
  extraLight: "#E0E4EF",
  main: "#d2c8ff",
  dark: "#a8a0cc",
  contrastText: black,
};

export const olive = {
  light: "#cccab9",
  extraLight: "#d9d7ca",
  main: "#bfbda7",
  dark: "#605f54",
  contrastText: white,
};

export const contentFont = "GT America Regular, Helvetica, Arial, sans-serif";
export const headlineFont = "GT America Condensed Regular, Helvetica, Arial, sans-serif";
export const ultralightFont = "Editorial New Ultralight, Times New Roman, Times, Georgia, serif";

export const contentText = {
  fontFamily: contentFont,
  fontSize: 16,
  lineHeight: "19pt",
  color: black,
};

export const importantValueFontSize = 72;
export const subHeadlineFontSize = 32;

const theme = createTheme({
  palette: {
    primary: olive,
    secondary: violet,
    text: {
      primary: black,
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: contentFont,
    h1: { fontFamily: headlineFont, fontWeight: 100 },
    h2: { fontFamily: headlineFont, fontWeight: 100 },
    h3: { fontFamily: headlineFont, fontWeight: 100 },
    h4: { fontFamily: headlineFont, fontWeight: 100 },
    h5: { fontFamily: headlineFont, fontWeight: 100 },
    h6: { fontFamily: headlineFont, fontWeight: 100 },
    caption: { fontSize: 12, color: olive.dark },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: black,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 16,
        },
        paperScrollPaper: {
          maxHeight: `calc(100% - ${16 * 2}px)`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: { margin: "16px 0" }, // Add the same style as in expanded mode
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: { padding: "0 16px" },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "black",
        },
        underlineHover: {
          transition: "opacity .2s ease-out,background-size .8s ease-in-out",
          backgroundImage: "linear-gradient(180deg,currentColor,currentColor)",
          backgroundOrigin: "content-box",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0 100%",
          backgroundSize: "100% 1px",
          "&:hover": {
            textDecoration: "none",
            opacity: 0.5,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: 0 },
      },
    },
  },
});

export default theme;
