import { Icon } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import React from "react";
import ImageWithPreview from "./ImageWithPreview";

const easeInTransition =
  "filterBrightness 2.5s cubic-bezier(0.4, 0.0, 0.2, 1), filterSaturate 3s cubic-bezier(0.4, 0.0, 0.2, 1), opacity 2s cubic-bezier(0.4, 0.0, 0.2, 1)";

const styles = (theme: Theme) =>
  createStyles({
    img: {
      transition: easeInTransition,
      maxWidth: "100%",
      maxHeight: "100%",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: "auto",
    },
    imgLoading: {
      opacity: 0,
    },
    container: {
      position: "relative",
    },
    iconContainer: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: {
      width: "100%",
      height: "100%",
      maxWidth: "50px",
      maxHeight: "50px",
      padding: "4px",
      color: theme.palette.grey["200"],
      alignContent: "center",
    },
  });
interface ImgProps extends WithStyles<typeof styles> {
  src: string;
  className?: string;
  imageClassName?: string;
  renderPlaceholder?: boolean;
  thumbnailUrl?: string;
}

interface ImgState {
  isLoaded: boolean;
  hasErrored: boolean;
  prevSrc: string;
}

class Img extends React.Component<ImgProps, ImgState> {
  static getDerivedStateFromProps(props: ImgProps, state: ImgState) {
    if (props.src !== state.prevSrc) {
      return { isLoaded: false, hasErrored: false, prevSrc: props.src };
    }

    return null;
  }

  constructor(props: ImgProps) {
    super(props);
    this.state = {
      isLoaded: false,
      hasErrored: false,
      prevSrc: props.src,
    };
  }

  render() {
    const { classes, className, imageClassName, renderPlaceholder, ...image } = this.props;
    const { hasErrored } = this.state;

    const containerClasses = classnames(classes.container, className);

    if (renderPlaceholder) {
      return (
        <div className={containerClasses}>
          <div className={classes.iconContainer}>
            {hasErrored && <Icon className={classes.icon}>broken_image</Icon>}
            {!hasErrored && <Icon className={classes.icon}>image</Icon>}
          </div>
        </div>
      );
    }

    return (
      <div className={containerClasses}>
        {image.src && this.renderImage()}
        {hasErrored && (
          <div className={classes.iconContainer}>
            <Icon className={classes.icon}>broken_image</Icon>
          </div>
        )}
      </div>
    );
  }

  private renderImage() {
    const { classes, className, imageClassName, renderPlaceholder, thumbnailUrl, ...image } = this.props;
    const { isLoaded } = this.state;

    const imgClasses = classnames(classes.img, imageClassName, {
      [classes.imgLoading]: !isLoaded,
    });

    if (thumbnailUrl) {
      return (
        <ImageWithPreview
          {...image}
          onLoad={this.onLoad}
          onError={this.onError}
          maxHeight={50}
          maxWidth={50}
          imgClassName={imgClasses}
        />
      );
    }
    return <img alt="" {...image} onLoad={this.onLoad} onError={this.onError} className={imgClasses} />;
  }

  private onLoad = () => this.setState({ isLoaded: true });
  private onError = () => this.setState({ hasErrored: true });
}

export default withStyles(styles)(Img);
