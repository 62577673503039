import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import store from "../../store";
import { contentText } from "../../theme";
import AdditionalDetail from "./AdditionalDetail";

type Props = {
  timeseriesIdx: number;
  sectionIdx?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: contentText,
    values: {
      marginTop: theme.spacing(2),
    },
    selectedEvent: {
      position: "absolute",
      top: theme.spacing(11),
    },
  }),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AdditionalDetails({ timeseriesIdx, sectionIdx = 2 }: Props) {
  const {
    translations: { incrementalImpactReport: translations },
  } = useContext(I18nContext);
  const i18n = useContext(I18nContext);
  const classes = useStyles();

  const summary = (store.impactData.reportData?.summary || [])[timeseriesIdx];

  if (!summary) return null;

  const ctr = Math.round((summary.clicks / summary.impressions) * 100);
  const spend = Math.round(summary.advertiser_cost);

  const selectedEvents = store.impactData.selectedAudienceEventNames()?.join(", ");

  return (
    <Grid justifyContent="space-between" container>
      <Typography variant="h6" className={classes.selectedEvent}>
        {selectedEvents}
      </Typography>
      <Grid item xs={8} md={3}>
        <AdditionalDetail
          title={translations.sections.clickThroughRate.title}
          value={i18n.formatNumber(ctr / 100, "percent")}
        >
          <Typography variant="caption" className={classes.content}>
            {translations.sections.clickThroughRate.description}
          </Typography>
          <Grid className={classes.values}>
            <Typography className={classes.content}>
              {`${translations.subSections.clicks}: ${i18n.formatNumber(summary.clicks)}`}
            </Typography>
            <Typography className={classes.content}>
              {`${translations.subSections.impressions}: ${i18n.formatNumber(summary.impressions)}`}
            </Typography>
          </Grid>
        </AdditionalDetail>
      </Grid>
      <Grid item xs={8} md={3}>
        <AdditionalDetail
          title={translations.sections.winRate.title}
          value={i18n.formatNumber(parseFloat(summary.win_rate) / 100, "percent")}
        >
          <Typography variant="caption" className={classes.content}>
            {translations.sections.winRate.description}
          </Typography>
        </AdditionalDetail>
      </Grid>
      <Grid item xs={8} md={3}>
        <AdditionalDetail title={translations.sections.spend.title} value={`€ ${i18n.formatNumber(spend).toString()}`}>
          <Typography variant="caption" className={classes.content}>
            {translations.sections.spend.description}
          </Typography>
        </AdditionalDetail>
      </Grid>
    </Grid>
  );
}

export default observer(AdditionalDetails);
