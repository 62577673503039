import TrackingService, {
  NullTrackingServiceAdapter,
  ProductionTrackingServiceAdapter,
} from "shared/services/TrackingService";

const trackingService = import.meta.env.VITE_IMPACT_UI_MIXPANEL_ACCESS_TOKEN
  ? new TrackingService(new ProductionTrackingServiceAdapter(import.meta.env.VITE_IMPACT_UI_MIXPANEL_ACCESS_TOKEN))
  : new TrackingService(new NullTrackingServiceAdapter());

export default trackingService;
