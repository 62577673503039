import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { I18nContext } from "../I18nContext";
import store from "../store";
import { darkGrey } from "../theme";
import ContentContainer from "./ContentContainer";
import { formatInterventionDate } from "./ImpactReportSelector";

type Props = {
  onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: "flex",
      flexDirection: "row",
    },
    label: {
      color: darkGrey,
      marginRight: theme.spacing(6),
      width: "8rem",
    },
    container: {
      marginLeft: theme.spacing(2),
    },
    selection: {
      marginBottom: theme.spacing(1),
    },
  }),
);

const ReportSummary = observer(({ onClick }: Props) => {
  const {
    translations: { incrementalImpactReport },
  } = useContext(I18nContext);

  const classes = useStyles();

  if (!store.impactData.reportData) return null;

  const selectedInterventions = store.impactData.reportData?.interventions.map(intervention => {
    return `${formatInterventionDate(intervention.attributes.start_time)} - ${formatInterventionDate(
      intervention.attributes.end_time,
    )}`;
  });
  const displayedInterventions = selectedInterventions?.map(currentIntervention => {
    return <div>{currentIntervention}</div>;
  });

  const selectedEvents = store.impactData.reportData?.audienceEvents.map(event => {
    return event.attributes.original_name;
  });
  const displayedEvents = selectedEvents?.map(event => {
    return <div>{event}</div>;
  });

  return (
    <div onClick={onClick}>
      <ContentContainer>
        <Grid md={8} container direction="row" justifyContent="space-between" className={classes.container}>
          <Grid direction="column" justifyContent="space-between">
            <Grid direction="row" item className={classes.item}>
              <Typography align="left" variant="h5">
                <div className={classes.label}>{incrementalImpactReport.form.interventions}</div>
              </Typography>
              <Typography align="left" className={classes.selection} variant="h6">
                {displayedInterventions}
              </Typography>
            </Grid>
            <Grid direction="row" item className={classes.item}>
              <Typography align="left" variant="h5">
                <div className={classes.label}>{incrementalImpactReport.form.audienceEventType}</div>
              </Typography>
              <Typography align="left" className={classes.selection} variant="h6">
                {store.impactData.reportData?.eventType}
              </Typography>
            </Grid>
          </Grid>
          <Grid direction="column" justifyContent="flex-start">
            <Grid direction="row" item className={classes.item}>
              <Typography align="left" variant="h5">
                <div className={classes.label}>{incrementalImpactReport.form.audienceEvent}</div>
              </Typography>
              <Typography align="left" className={classes.selection} variant="h6">
                {displayedEvents}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ContentContainer>
    </div>
  );
});

export default ReportSummary;
