import { CurrencyCode } from "shared/utilities/isoCodes";

export default class Money {
  constructor(
    public value: number,
    public currencyCode: CurrencyCode,
  ) {
    return Object.freeze(this);
  }

  public static microcurrency(microcurrencyValue: number, currencyCode: CurrencyCode) {
    return new Money(microcurrencyValue / 10 ** 6, currencyCode);
  }

  public static cents(cents: number, currencyCode: CurrencyCode) {
    return new Money(cents / 100, currencyCode);
  }
}
