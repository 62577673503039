// https://youmightnotneed.com/lodash/#zip
export const zip = (arr: any[], ...args: any[]) => arr.map((value, idx) => [value, ...args.map(arr => arr[idx])]);

export const sortBy = <T>(
  collection: T[],
  valueExtractor: (obj: T) => string | number | Date,
  reverse: boolean = false,
) =>
  collection.sort((a: T, b: T) => {
    const aVal = valueExtractor(a);
    const bVal = valueExtractor(b);
    return reverse ? (aVal < bVal ? 1 : bVal < aVal ? -1 : 0) : aVal > bVal ? 1 : bVal > aVal ? -1 : 0;
  });
