export type DurationInMiliseconds = number;
export type DurationInMinutes = number;

export function durationInMinutesToMiliseconds(duration: DurationInMinutes): DurationInMiliseconds {
  return duration * 60 * 1000;
}

export async function measureDuration<T>(promise: Promise<T>): Promise<{ result: T; duration: number }> {
  const startTime = Date.now();

  const result = await promise;

  const endTime = Date.now();
  const duration = endTime - startTime;

  return { result, duration };
}

export function humanizeDurationString(durationString: string) {
  if (!durationString) {
    return "";
  }

  if (durationString[0] === "P") {
    return durationString.slice(1);
  }
  return durationString;
}
