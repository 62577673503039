import { ImpactReportEventSummary } from "shared/http/apiTypes";
import triggerFileDownload from "shared/utilities/triggerFileDownload";

export default function reportSummaryToCSV(summary: ImpactReportEventSummary) {
  let dataRows = [] as string[];
  for (var key in summary) {
    // @ts-expect-error
    let value = summary[key];

    if (typeof value === "object") {
      for (var subKey in value) {
        let subValue = value[subKey];
        dataRows.push([`${key} ${subKey}`, subValue].join(","));
      }
    } else {
      dataRows.push([key, value].join(","));
    }
  }
  return dataRows.join("\n");
}

export function triggerReportCsvDownload(filename: string, summary: ImpactReportEventSummary) {
  const content = reportSummaryToCSV(summary);

  triggerFileDownload("csv", filename, content);
}
