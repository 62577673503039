import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Chip, InputAdornment, InputProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import isEqual from "lodash/isEqual";
import React from "react";
import { ISelectOption, optionKey } from "shared/components/ISelectOption";
import TextField, { TextFieldProps } from "shared/components/TextField";
import { OptionMenu, styles as baseStyles, getOptions, itemToString, removeDuplicateValues } from "./Shared";

const styles = (theme: Theme) =>
  createStyles({
    inputContainer: {
      flexWrap: "wrap",
    },
    chipContainer: {
      paddingTop: theme.spacing(1),
      maxWidth: "100%",
    },
    chip: {
      margin: "0 4px 2px 0",
      height: `calc(2 * ${theme.typography.button.fontSize})`,
    },
    invalidChip: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
      "&:focus": {
        backgroundColor: theme.palette.error.main,
      },
    },
    chipWithLink: {
      "&:hover": {
        "& .MuiChip-label": {
          textDecoration: "underline",
        },
      },
    },
    deletable: {
      maxWidth: "100%",
      display: "inline-flex",
    },
    endAdornmentContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: theme.spacing(2),
    },
    ...baseStyles(theme),
  });

interface IMaterialAutocompleteOption extends ISelectOption {
  isValid: boolean;
}
export const selectAllOption = Object.freeze({ label: "Select All", value: "__select_all__" });

export interface IMaterialAutocompleteMultipleProps
  extends Omit<TextFieldProps, "classes" | "onChange" | "value" | "defaultValue"> {
  options: ISelectOption[];
  values: any[];
  onChange: (values: any) => void;
  endAdornment?: React.ReactNode;
  inputTitle?: string;
}

interface IMaterialAutocompleteMultipleState {
  query: string;
  hasFocus: boolean;
  openedByClick: boolean;
}

class MaterialAutocompleteMultiple extends React.Component<
  IMaterialAutocompleteMultipleProps & WithStyles<typeof styles, true>,
  IMaterialAutocompleteMultipleState
> {
  private popperNode: React.RefObject<HTMLDivElement>;
  private searchInputRef: React.RefObject<HTMLInputElement> = React.createRef();

  constructor(props: IMaterialAutocompleteMultipleProps & WithStyles<typeof styles, true>) {
    super(props);
    this.popperNode = React.createRef<HTMLDivElement>();
    this.state = {
      query: "",
      hasFocus: false,
      openedByClick: false,
    };
  }

  public render() {
    const { handleChange, renderInput } = this;
    const {
      id,
      placeholder,
      classes,
      options: allOptions,
      theme,
      values,
      fullWidth,
      endAdornment,
      inputTitle,
      ...restProps
    } = this.props;

    const options = removeDuplicateValues(allOptions);

    const selectedOptions = this.getSelectedOptions();

    const withSearchBar = !!inputTitle;
    return (
      <Downshift
        id={id}
        selectedItem={selectedOptions}
        onChange={handleChange}
        itemToString={itemToString}
        defaultHighlightedIndex={0}
        stateReducer={stateReducer}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          selectedItem: currentlySelectedItem,
          highlightedIndex,
          openMenu,
          closeMenu,
          setItemCount,
          setHighlightedIndex,
        }) => {
          const suggestedOptions = getOptions({
            query: this.state.query,
            options,
            selectedValues: values,
            includeSelectAll: options.length > 1,
          });
          const rowCount = suggestedOptions.length;
          setItemCount(rowCount);
          if (highlightedIndex! >= rowCount && rowCount > 0) {
            setHighlightedIndex(0);
          }

          const rowHeight = theme!.typography.fontSize * 3;
          const onFocus = () => {
            this.setState(state => (!state.hasFocus ? { hasFocus: true } : null));
            openMenu();
          };
          const onBlur = () => {
            this.setState(state => (state.hasFocus ? { hasFocus: false, openedByClick: false } : null));
          };

          const onClick = () => {
            if (this.state.openedByClick && isOpen) {
              this.setState({ openedByClick: false, hasFocus: false });
              closeMenu();
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              this.setState(state => ({ hasFocus: true, openedByClick: true }));
              openMenu();
            }
          };

          const checkBoxProps = {
            withCheckbox: true,
            onSearch: this.handleInputChange,
            allSelected: values.length === options.filter(option => !option.disabled).length,
            getInputProps,
            searchQuery: this.state.query,
            searchInputRef: this.searchInputRef,
          };

          return (
            <div className={fullWidth ? classes.fullWidthContainer : classes.container}>
              {renderInput(selectedOptions, {
                fullWidth,
                ...restProps,
                InputProps: getInputProps({
                  onClick,
                  onFocus,
                  onBlur,
                  id,
                  placeholder,
                  onKeyDown: this.handleKeyDown.bind(this),
                }) as InputProps,
                ref: this.popperNode,
              })}
              <OptionMenu
                {...{
                  isOpen,
                  rowCount,
                  rowHeight,
                  highlightedIndex,
                  getItemProps,
                  getMenuProps,
                  anchorNode: this.popperNode.current,
                  selectedItemKey: currentlySelectedItem,
                  options: suggestedOptions,
                  withSearchBar,
                  checkBoxProps,
                }}
              />
            </div>
          );
        }}
      </Downshift>
    );
  }

  private handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { query } = this.state;
    const selectedOptions = this.getSelectedOptions();

    if (selectedOptions.length && !query.length && event.key === "Backspace") {
      const values = selectedOptions.slice(0, selectedOptions.length - 1).map(o => o.value);
      this.props.onChange(values);
    } else if (event.key === "Tab" && this.searchInputRef.current) {
      event.preventDefault();
      this.searchInputRef.current.focus();
    }
  };

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ query: event.target.value });
  };

  private handleChange = (option: ISelectOption | ISelectOption[]) => {
    const { values, onChange, options } = this.props;
    const changedOption = Array.isArray(option) ? option[0] : option;

    if (changedOption.value === selectAllOption.value) {
      this.handleSelectAll(values, options, onChange);
    } else {
      this.handleSingleOption(changedOption, values, onChange);
    }
  };

  private handleSelectAll = (selectedValues: any[], allOptions: ISelectOption[], onChange: (values: any[]) => void) => {
    const availableOptions = allOptions.filter(option => !option.disabled);
    const allAvailableOptionsSelected = availableOptions.length === selectedValues.length;

    if (allAvailableOptionsSelected) {
      onChange([]);
    } else {
      const newSelectedValues = availableOptions.map(option => option.value);
      onChange([...newSelectedValues]);
    }
  };

  private handleSingleOption = (option: ISelectOption, currentValues: any[], onChange: (values: any[]) => void) => {
    const newValue = option.value;
    const isSelected = currentValues.includes(newValue);

    if (!isSelected) {
      onChange([...currentValues, newValue]);
    } else {
      const newValues = currentValues.filter(value => value !== newValue);
      onChange(newValues);
    }
  };

  private handleDelete = (option: ISelectOption) => () => {
    const { values } = this.props;

    const newValues = values.filter(value => !isEqual(optionKey(option.value), optionKey(value)));
    this.props.onChange(newValues);
  };

  private handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    const pastedText = event.clipboardData.getData("Text");
    if (pastedText.indexOf(",") === -1) return;

    event.preventDefault();

    const { options, onChange } = this.props;
    const values = [...this.props.values];

    const pastedStrings = pastedText.split(",").map(value => value.trim());

    const pastedValues = pastedStrings.map(pastedString => {
      const option = options.find(({ label, value }) => pastedString === value || pastedString === label);

      if (option) return option.value;

      return pastedString;
    });

    pastedValues.forEach(pastedValue => {
      if (values.indexOf(pastedValue) === -1) values.push(pastedValue);
    });

    onChange(values);
  };

  private renderInput = (
    selectedOptions: IMaterialAutocompleteOption[],
    textFieldProps: TextFieldProps & { ref: React.Ref<HTMLDivElement> },
  ) => {
    const { label, InputProps, InputLabelProps, ref, variant, ...other } = textFieldProps;
    const { classes, disabled, endAdornment, inputTitle } = this.props;
    const { query, hasFocus } = this.state;

    const isFieldRequired = textFieldProps.required && selectedOptions.length === 0;

    if (inputTitle) {
      return (
        <TextField
          InputProps={{
            inputRef: ref,
            ...InputProps,
            value: inputTitle,
            sx: { caretColor: "transparent" },
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
            required: isFieldRequired,
          }}
          label={label}
          {...other}
        />
      );
    }

    const chips = selectedOptions.map(option => {
      return (
        <Chip
          key={optionKey(option.value)}
          label={option.label}
          onClick={option.link ? () => window.open(option.link) : undefined}
          onDelete={disabled ? undefined : this.handleDelete(option)}
          className={classnames(
            classes.chip,
            { [classes.invalidChip]: !option.isValid, [classes.chipWithLink]: option.link },
            classes.deletable,
          )}
        />
      );
    });
    const chipContainer = chips.length > 0 ? <div className={classes.chipContainer}>{chips}</div> : null;

    return (
      <TextField
        onPaste={this.handlePaste}
        InputProps={{
          inputRef: ref,
          className: classes.inputContainer,
          startAdornment: chipContainer,
          endAdornment: endAdornment && <div className={classes.endAdornmentContainer}>{endAdornment}</div>,
          ...InputProps,
          value: query,
          onChange: this.handleInputChange,
          required: isFieldRequired,
        }}
        label={label}
        disabled={disabled}
        InputLabelProps={{
          shrink: chips.length > 0 || query.length > 0 || hasFocus,
          ...InputLabelProps,
        }}
        variant={variant}
        {...other}
      />
    );
  };

  private getSelectedOptions = () => {
    const { options, values } = this.props;
    const selectedOptions: IMaterialAutocompleteOption[] = [];

    values.forEach(value => {
      const selectedOption = options.find(o => optionKey(o.value) === optionKey(value));

      if (selectedOption) {
        selectedOptions.push({ isValid: true, ...selectedOption });
      } else {
        selectedOptions.push({ value, label: optionKey(value).toString(), isValid: false });
      }
    });
    return selectedOptions;
  };
}

function stateReducer(state: DownshiftState<ISelectOption>, changes: StateChangeOptions<ISelectOption>) {
  // this prevents the menu from being closed when the user
  // selects an item with a keyboard or mouse
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
    case Downshift.stateChangeTypes.blurInput:
      return {
        ...changes,
        isOpen: state.isOpen,
        highlightedIndex: state.highlightedIndex,
      };
    default:
      return changes;
  }
}

export default withStyles(styles, { withTheme: true })(MaterialAutocompleteMultiple);
