import { Tooltip as MUITooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "@mui/material/styles";

function TooltipWithPopper({ className, ...props }: TooltipProps) {
  return <MUITooltip {...props} classes={{ popper: className }} />;
}

const StyledTooltip = styled(TooltipWithPopper)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.main,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
}));

function Tooltip(props: TooltipProps) {
  return <StyledTooltip {...props} arrow />;
}

export default Tooltip;
