import { Box, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import React from "react";
import store from "../store";
import { contentText, subHeadlineFontSize } from "../theme";

type Props = {
  title: string;
  description: string;
  children: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3, 1, 12, 1),
    },
    title: {
      fontSize: subHeadlineFontSize,
    },
    description: contentText,
    selectedEvent: {
      padding: theme.spacing(0, 0, 6, 0),
    },
  }),
);

const PageSubSection = observer(({ title, description, children }: Props) => {
  const classes = useStyles();

  const selectedEvents = store.impactData.selectedAudienceEventNames()?.join(", ");

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item md={4} xs={6}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="h6" className={classes.selectedEvent}>
            {selectedEvents}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {description}
          </Typography>
        </Grid>
        <Grid item md={4} xs={6}>
          {children}
        </Grid>
      </Grid>
    </Box>
  );
});

export default PageSubSection;
