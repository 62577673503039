import { configure } from "mobx";
import ImpactDataStore from "./impactData";
import OrganizationsStore from "./organizations";

// Ensuring that state is only modified by tagged
// functions is a nice idea but the support for
// async/await generators is confusing and it
// usually wastes more time than it saves
configure({
  enforceActions: "never",
});

const store = {
  impactData: new ImpactDataStore(),
  organizations: new OrganizationsStore(),
};

export type Store = typeof store;

if (process.env.NODE_ENV === "development") {
  (global.window as any).store = store;
}

export default store;
