import { ILocaleFormatFunctions, TemplateTagFunction } from "shared/utilities/i18n";

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const en = (t: TemplateTagFunction, i18n: ILocaleFormatFunctions) => {
  return {
    common: {
      logout: t`Logout`,
      okAction: t`OK`,
      cancelAction: t`Cancel`,
    },
    dateRangeSelect: {
      toMonth: (numberOfMonth: number) => MONTHS[numberOfMonth],
      startDate: "Start date",
      endDate: "End date",
      phrases: {
        calendarLabel: "Calendar",
        closeDatePicker: "Close",
        focusStartDate: "Interact with the calendar and add the check-in date for your trip",
        clearDate: "Clear Date",
        clearDates: "Clear Dates",
        jumpToPrevMonth: "Move backward to switch to the previous month",
        jumpToNextMonth: "Move forward to switch to the next month",
        keyboardShortcuts: "Keyboard Shortcuts",
        showKeyboardShortcutsPanel: "Open the keyboard shortcuts panel",
        hideKeyboardShortcutsPanel: "Close the shortcuts panel",
        openThisPanel: "Open this panel",
        enterKey: "Enter key",
        leftArrowRightArrow: "Right and left arrow keys",
        upArrowDownArrow: "up and down arrow keys",
        pageUpPageDown: "page up and page down keys",
        homeEnd: "Home and end keys",
        escape: "Escape key",
        questionMark: "Question mark",
        selectFocusedDate: "Select the date in focus",
        moveFocusByOneDay: "Move backward (left) and forward (right) by one day",
        moveFocusByOneWeek: "Move backward (up) and forward (down) by one week",
        moveFocusByOneMonth: "Switch months",
        moveFocustoStartAndEndOfWeek: "Go to the first or last day of a week",
        returnFocusToInput: "Return to the date input field",
        keyboardNavigationInstructions: `Press the down arrow key to interact with the calendar and
  select a date. Press the question mark key to get the keyboard shortcuts for changing dates`,
      },
    },
    autocomplete: {
      createOptionLabel: (name: string) => t`Create "${name}"`,
    },
    listField: {
      helperText: t`Enter one value per line or separate them by commas`,
    },
    durationSelector: {
      days: t`Days`,
      hours: t`Hours`,
      minutes: t`Minutes`,
      infinity: t`Infinity`,
      notUsed: t`Not Used`,
    },
  };
};

export default en;
