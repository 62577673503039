import { I18nContext } from "@client/I18nContext";
import LogoutIcon from "@mui/icons-material/Logout";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import logo from "../logo.svg";
import { black, white } from "../theme";
import ContentContainer from "./ContentContainer";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: black,
      color: white,
    },
    logo: {
      textTransform: "uppercase",
      marginLeft: 16,
      fill: white,
    },
  }),
);

function Logo() {
  const classes = useStyles();

  if (process.env.NODE_ENV === "production") {
    return <img alt="" src={logo} height="25" />;
  }
  return (
    <>
      <img alt="" src={logo} height="25" />
      <Typography className={classes.logo} variant="h6">
        {import.meta.env.VITE_ENV_NAME || "dev"}
      </Typography>
    </>
  );
}

export interface Props {
  logOut: () => void;
  userName?: string;
}

function TopBar({ logOut, userName }: Props) {
  const classes = useStyles();
  const translations = useContext(I18nContext).translations;

  return (
    <AppBar position="static" elevation={1} className={classes.root}>
      <ContentContainer>
        <Toolbar>
          <Logo />

          <div style={{ flexGrow: 1 }} />

          {userName && (
            <Typography data-testid="username" color="inherit">
              {userName}
            </Typography>
          )}
          <IconButton title={translations.common.logout} color="inherit" onClick={logOut} size="large">
            <LogoutIcon fill={white} />
          </IconButton>
        </Toolbar>
      </ContentContainer>
    </AppBar>
  );
}

export default observer(TopBar);
