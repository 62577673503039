import { Box, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React from "react";
import { importantValueFontSize } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 0, 4, 0),
        textAlign: "left",
      },
    },
    spaced: {
      marginBottom: theme.spacing(2),
    },
    bottomAlignContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: 20,
    },
    value: {
      fontSize: importantValueFontSize,
    },
  }),
);

export type Props = {
  title: string;
  value: string;
  children?: React.ReactNode;
};

function AdditionalDetail({ title, value, children }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12} sm={12} className={classes.bottomAlignContent}>
          <Typography className={classnames(classes.title, classes.spaced)} variant="h6">
            {title}
          </Typography>
          <Typography className={classnames(classes.value, classes.spaced)} variant="h3">
            {value}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdditionalDetail;
