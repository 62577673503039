import { Container, Grid } from "@mui/material";
import React from "react";

export default function PageContainer({ children }: React.PropsWithChildren<{}>) {
  return (
    <Grid container direction="column" alignContent="center">
      <Container maxWidth="sm">{children as React.ReactElement<any>}</Container>
    </Grid>
  );
}
