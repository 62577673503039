import { ComputedDatum, CustomLayerProps } from "@nivo/line";
import { area, curveMonotoneX } from "d3-shape";
import { olive } from "../../theme";

interface PredictionAreaLayerProps extends CustomLayerProps {
  areaSeriesId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PredictionAreaLayer({ series, xScale, yScale, innerHeight, areaSeriesId }: PredictionAreaLayerProps) {
  const areaGenerator = area<ComputedDatum>()
    .x(d => xScale(d.data.x || 0))
    .y0(d => yScale(d.data.upper || 0))
    .y1(d => yScale(d.data.lower || 0))
    .curve(curveMonotoneX);

  const areaSeries = series.find(s => s.id === areaSeriesId);
  if (!areaSeries) return null;

  const areaData = areaSeries.data.map(datum =>
    isNaN(datum.data.upper) ? { ...datum, data: { ...datum.data, y: null } } : datum,
  );

  return <path d={areaGenerator(areaData) || ""} fill={olive.main} fillOpacity={0.6} />;
}

export default PredictionAreaLayer;
