import { makeAutoObservable, runInAction, toJS } from "mobx";
import { default as moment } from "moment";
import * as MomentRange from "moment-range";
import { AudienceEventResource, ImpactReportEventSummary, ImpactReportTimeseries } from "shared/http/apiTypes";
import { InterventionResource } from "shared/http/apiTypes/experiment";
import { data } from "shared/http/jsonApi";
import { JsonApiError } from "shared/services/HttpService";
import { FieldError } from "shared/utilities/form/FormState";
import jsonApiErrorsToObject from "shared/utilities/jsonApiErrorsToObject";
import { formatInterventionDate } from "../components/ImpactReportSelector";
import store from "../store";
import { triggerReportCsvDownload } from "../utilities/generateReportCsv";
import { ImpactSeries, apiTimeseriesToNivoSerie } from "../utilities/impactData";
import remergeApi from "../utilities/remergeApi";

// @ts-expect-error
const rangeMoment = MomentRange.extendMoment(moment);

type ApiResource = "audienceEvents" | "interventions" | "impactReport";

type QueryParams = {
  experimentId?: string;
  selectedAudienceEventIds?: string;
  selectedInterventionIds?: string;
  selectedAudienceEventType?: AudienceEventType;
};

type FormErrors = {
  experiment: FieldError[];
  interventions: FieldError[];
  events: FieldError[];
  revenue: FieldError[];
};

export enum AudienceEventType {
  Count = "count",
  USD = "usd",
  EUR = "eur",
}

interface ReportData {
  dataSeries: ImpactSeries[] | null;
  summary: ImpactReportEventSummary[] | null;
  interventions: InterventionResource[];
  audienceEvents: AudienceEventResource[];
  eventType: string;
}
class ImpactDataStore {
  apiErrors: { [key in ApiResource]?: boolean };
  interventions: InterventionResource[];

  audienceEvents: AudienceEventResource[];
  availableAudienceEvents: AudienceEventResource[];
  interventionsAreAvailable: boolean | null;

  organizationId: string;
  experimentId: string;
  selectedAudienceEventType: AudienceEventType;
  selectedAudienceEventIds: string[];
  selectedInterventionIds: string[];
  isRevenueEvent: boolean;
  currency: string | null;
  formErrors: FormErrors;
  formDataLoading: boolean;
  dataLoading: boolean;
  isFormExpanded: boolean;
  reportData: ReportData | null;

  interventionCalendarStartDate: Date;
  interventionCalendarEndDate: Date;

  constructor() {
    this.reset();
    makeAutoObservable(this);
  }

  public switchExperiment({ organizationId, experimentId }: { experimentId: string; organizationId: string }) {
    if (this.experimentId === experimentId && this.organizationId === organizationId) return;
    this.reset();

    this.organizationId = organizationId;
    this.experimentId = experimentId;
  }

  public setURLData(queryParams: QueryParams) {
    const {
      selectedAudienceEventIds = [],
      selectedInterventionIds = [],
      selectedAudienceEventType = AudienceEventType.Count,
    } = queryParams;

    if (!this.experimentId) return;

    const selectedAudienceEventIdsArray = Array.isArray(selectedAudienceEventIds)
      ? selectedAudienceEventIds
      : selectedAudienceEventIds.split(",");
    const selectedInterventionIdsArray = Array.isArray(selectedInterventionIds)
      ? selectedInterventionIds
      : selectedInterventionIds.split(",");

    this.switchAudienceEventType(selectedAudienceEventType);

    this.selectedAudienceEventIds = selectedAudienceEventIdsArray;
    this.selectedInterventionIds = selectedInterventionIdsArray;
  }

  public async fetchInterventionsAndEvents(audienceId: string) {
    if (!this.experimentId || !audienceId) return;

    const [interventions, audienceEvents] = await Promise.all([
      this.wrapApiCall<InterventionResource[]>("formDataLoading", "interventions", () =>
        remergeApi.interventionsIndex(this.experimentId),
      ),
      this.wrapApiCall<AudienceEventResource[]>("formDataLoading", "audienceEvents", () =>
        remergeApi.audienceEventsIndex(audienceId),
      ),
    ]);

    if (interventions && audienceEvents)
      runInAction(() => {
        this.interventionsAreAvailable = interventions.length > 0;
        this.interventions = interventions;
        this.availableAudienceEvents = audienceEvents;
      });
  }

  public selectedEventsWithoutRevenueSupport(): AudienceEventResource[] {
    return this.selectedAudienceEventIds.reduce((memo, audienceEventId) => {
      const audienceEvent = this.audienceEvents.find(audienceEvent => audienceEvent.id === audienceEventId);

      if (!audienceEvent || audienceEvent.attributes.count_revenue) return memo;

      return [...memo, audienceEvent];
    }, []);
  }

  public hasErrors() {
    return Object.values(this.apiErrors).some(errors => errors);
  }

  public toggleFormState() {
    this.isFormExpanded = !this.isFormExpanded;
  }

  public saveCurrentlyLoadedReportSelection(
    selectedInterventions: InterventionResource[],
    selectedAudienceEvents: AudienceEventResource[],
  ) {
    if (this.reportData) {
      this.reportData.interventions = selectedInterventions;
      this.reportData.audienceEvents = selectedAudienceEvents;
      this.reportData.eventType = this.selectedAudienceEventType;
    }
  }

  public saveReportFormState = () => {
    const selectedInterventionsData = this.selectedInterventionIds.flatMap(id =>
      this.interventions.filter(intervention => intervention.id === id),
    );

    const selectedEventsData = this.selectedAudienceEventIds.flatMap(id =>
      this.audienceEvents.filter(audienceEvent => audienceEvent.id === id),
    );

    this.saveCurrentlyLoadedReportSelection(selectedInterventionsData, selectedEventsData);
  };

  public selectedAudienceEventNames = () => {
    return this.reportData?.audienceEvents.map(event => {
      return event.attributes.original_name;
    });
  };

  public hasNegativeResult(): boolean {
    if (!this.reportData?.summary) return false;

    const incrementalEvents = this.reportData?.summary[0].incremental_events.mean;
    return Number.isNaN(incrementalEvents / 1) || incrementalEvents <= 0;
  }

  protected async wrapApiCall<ResponseData>(
    loadingProp: string,
    apiResource: ApiResource,
    call: () => Promise<{ data: ResponseData }>,
  ): Promise<ResponseData | null> {
    // @ts-expect-error
    this[loadingProp] = true;
    this.apiErrors[apiResource] = false;
    try {
      return await data(call());
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      this.apiErrors[apiResource] = true;
      return null;
    } finally {
      // @ts-expect-error
      this[loadingProp] = false;
    }
  }

  get requiredReportInputsSet() {
    const formFilledIn =
      this.experimentId && this.selectedAudienceEventIds.length > 0 && this.selectedInterventionIds.length > 0;
    return !formFilledIn || (!this.dataLoading && this.formDataLoading);
  }

  public fetchReport = async () => {
    this.dataLoading = true;

    try {
      const impactReport = await data(
        remergeApi.impactReportCreate({
          type: "impact_report",
          attributes: {
            event_ids: toJS(this.selectedAudienceEventIds),
            experiment_id: toJS(Number(this.experimentId)),
            organization_id: toJS(Number(this.organizationId)),
            intervention_ids: toJS(this.selectedInterventionIds.map(Number)),
            is_revenue_event: this.isRevenueEvent,
            ...(this.currency ? { currency: this.currency } : {}),
          },
        }),
      );

      this.saveReportFormState();
      this.toggleFormState();

      runInAction(() => {
        if (this.reportData) {
          this.reportData.dataSeries = impactReport.attributes.timeseries.map((ts: ImpactReportTimeseries) => ({
            event: ts.event,
            data: apiTimeseriesToNivoSerie(ts.timeseries),
          }));
          this.reportData.summary = [...impactReport.attributes.summary];
        }
      });
    } catch (error) {
      if (error instanceof JsonApiError) {
        const errorObject = jsonApiErrorsToObject(error.response.body.errors, "/data/attributes");
        // @ts-expect-error
        Object.entries(errorObject).forEach(([field, fieldError]) => (this.formErrors[field] = fieldError));
        return;
      }

      this.apiErrors["impactReport"] = true;
    } finally {
      this.dataLoading = false;
    }
  };

  public downloadReport = () => {
    if (this.reportData?.summary) {
      this.reportData.summary.forEach(summary => {
        let filename = `Impact_report_${this.organizationId}_${this.experimentId}_${this.selectedInterventionIds}.csv`;
        triggerReportCsvDownload(filename, summary);
      });
    }
  };

  public switchAudienceEventType(type: AudienceEventType) {
    this.selectedAudienceEventType = type;

    if (this.selectedAudienceEventType === AudienceEventType.Count) {
      this.audienceEvents = this.availableAudienceEvents;
      this.isRevenueEvent = false;
      this.currency = null;
      return;
    }

    this.isRevenueEvent = true;
    this.audienceEvents = this.availableAudienceEvents.filter(event => event.attributes.count_revenue === true);
    const availableIds = new Set(this.audienceEvents.map(event => event.id));
    this.selectedAudienceEventIds = this.selectedAudienceEventIds.filter(eventId => availableIds.has(eventId));
    this.currency = type;
  }

  public calculateCalendarRange = () => {
    const { startDate, endDate } = this.interventions.reduce<{ startDate: Date | null; endDate: Date | null }>(
      (acc, intervention) => {
        if (acc.startDate === null) acc.startDate = intervention.attributes.start_time;
        if (acc.endDate === null) acc.endDate = intervention.attributes.end_time;

        return {
          startDate:
            intervention.attributes.start_time < acc.startDate ? intervention.attributes.start_time : acc.startDate,
          endDate: intervention.attributes.start_time > acc.endDate ? intervention.attributes.end_time : acc.endDate,
        };
      },
      { startDate: null, endDate: null },
    );

    if (startDate === null || endDate === null) {
      return;
    }

    // mobx doesn't handle dates natively and treats them like plain
    // objects so we need to check the actual values here before
    // deciding whether to update the store
    const startEqual = this.interventionCalendarStartDate === startDate;
    const endEqual = this.interventionCalendarEndDate === endDate;

    if (!startEqual && !endEqual) {
      this.interventionCalendarStartDate = startDate;
      this.interventionCalendarEndDate = endDate;
    }
  };

  public renderCalendarEvents = () => {
    const selectedOrg = store.organizations.organizations[this.organizationId];

    return this.interventions.map(intervention => ({
      content: `[${intervention.id}] ${selectedOrg?.attributes?.name} - ${formatInterventionDate(
        intervention.attributes?.start_time,
      )} - ${formatInterventionDate(intervention.attributes?.end_time)}`,
      range: rangeMoment.range(intervention.attributes?.start_time, intervention.attributes?.end_time),
      id: intervention.id,
      colorIndex: store.impactData.selectedInterventionIds.indexOf(intervention.id) === -1 ? "0" : "1",
    }));
  };

  public reset() {
    this.organizationId = "";
    this.interventions = [];
    this.availableAudienceEvents = [];
    this.audienceEvents = [];
    this.interventionsAreAvailable = null;
    this.experimentId = "";
    this.selectedAudienceEventType = AudienceEventType.Count;
    this.selectedAudienceEventIds = [];
    this.selectedInterventionIds = [];
    this.isRevenueEvent = false;
    this.currency = null;
    this.formDataLoading = false;
    this.dataLoading = false;
    this.apiErrors = {};
    this.formErrors = { experiment: [], interventions: [], events: [], revenue: [] };
    this.isFormExpanded = true;
    this.reportData = {
      summary: null,
      dataSeries: null,
      interventions: [],
      audienceEvents: [],
      eventType: "",
    };
  }
}

export default ImpactDataStore;
