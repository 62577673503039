import { Box, Grid, Icon, InputAdornment, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { toJS } from "mobx";
import { Observer, observer } from "mobx-react-lite";
import { useContext } from "react";
import MaterialAutocompleteMultipleField from "shared/components/form/MaterialAutocompleteMultipleField";
import MaterialRadioGroup from "shared/components/form/MaterialRadioGroup";
import RemergeApi from "shared/http/RemergeApi";
import { InterventionResource } from "shared/http/apiTypes/experiment";
import { I18nContext } from "../I18nContext";
import store from "../store";
import { AudienceEventType } from "../store/impactData";
import { darkGrey } from "../theme";
import { sortBy } from "../utilities/array";
import ContentContainer from "./ContentContainer";
import FetchReportButton from "./FetchReportButton";

type Props = {
  remergeApi: RemergeApi;
  organizationId: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      height: "100%",
      color: darkGrey,
    },
    calendarContainer: {
      marginTop: theme.spacing(4),
      display: "flex",
      alignItems: "center",
    },
  }),
);

const EndAdornment = (
  <InputAdornment position="end">
    <Icon>arrow_drop_down</Icon>
  </InputAdornment>
);

function FormLabel({ text }: { text: string }) {
  const classes = useStyles();
  return (
    <Box className={classes.label}>
      <Typography align="left" variant="h5">
        {text}
      </Typography>
    </Box>
  );
}

const AudienceEventSelector = observer(() => {
  const options = (store.impactData.audienceEvents || []).map(audienceEvent => {
    let label = audienceEvent.attributes.original_name;

    if (audienceEvent.attributes.count_revenue) {
      label += " ($/€)";
    }

    return {
      value: audienceEvent.id,
      label,
    };
  });

  return (
    <MaterialAutocompleteMultipleField
      fullWidth
      id="audienceEvent"
      name="audienceEvent"
      options={options}
      value={store.impactData.selectedAudienceEventIds}
      onChange={ids => (store.impactData.selectedAudienceEventIds = ids)}
      label=""
      errors={store.impactData.formErrors.events}
      disabled={store.impactData.audienceEvents.length === 0 || store.impactData.formDataLoading}
      variant="standard"
      endAdornment={EndAdornment}
    />
  );
});

const EventTypeSelector = observer(() => {
  const {
    translations: { audienceEventTypes, disabledBecauseEventDoesNotSupportRevenue },
  } = useContext(I18nContext);

  const audienceEventsWithoutRevenueSupport = store.impactData.selectedEventsWithoutRevenueSupport();

  let disabledHint: undefined | string;

  if (audienceEventsWithoutRevenueSupport.length > 0) {
    const audienceEventNames = audienceEventsWithoutRevenueSupport.map(event => event.attributes.original_name);
    disabledHint = disabledBecauseEventDoesNotSupportRevenue(audienceEventNames);
  }

  return (
    <MaterialRadioGroup
      id="EventType"
      name="EventType"
      value={store.impactData.selectedAudienceEventType}
      onChange={(selected: AudienceEventType) => store.impactData.switchAudienceEventType(selected)}
      options={[
        { value: AudienceEventType.Count, label: audienceEventTypes.count },
        {
          value: AudienceEventType.EUR,
          label: audienceEventTypes.eur,
          disabledHint,
        },
        {
          value: AudienceEventType.USD,
          label: audienceEventTypes.usd,
          disabledHint,
        },
      ]}
      label=""
      errors={store.impactData.formErrors.revenue}
    />
  );
});

export const formatInterventionDate = (date: Date) => {
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${date.getUTCFullYear()}-${month}-${day} ${hours}:${minutes}`;
};

const sortInterventions = (interventions: InterventionResource[]) =>
  sortBy<InterventionResource>(interventions, intervention => intervention.attributes.start_time, true);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ImpactReportSelector({ remergeApi, organizationId }: Props) {
  const {
    translations: { incrementalImpactReport },
  } = useContext(I18nContext);

  return (
    <ContentContainer>
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item md={2} xs={12}>
            <FormLabel text={incrementalImpactReport.form.interventions} />
          </Grid>
          <Grid item md={10} xs={12}>
            <Observer>
              {() => (
                <MaterialAutocompleteMultipleField
                  fullWidth
                  id="interventions"
                  name="interventions"
                  options={sortInterventions(store.impactData.interventions.slice() || []).map(intervention => ({
                    value: intervention.id,
                    label: `${formatInterventionDate(intervention.attributes.start_time)} - ${formatInterventionDate(
                      intervention.attributes.end_time,
                    )}`,
                  }))}
                  label=""
                  errors={store.impactData.formErrors.interventions}
                  onChange={(ids: string[]) => (store.impactData.selectedInterventionIds = ids)}
                  value={toJS(store.impactData.selectedInterventionIds)}
                  disabled={!store.impactData.interventionsAreAvailable}
                  variant="standard"
                  endAdornment={EndAdornment}
                />
              )}
            </Observer>
          </Grid>
          <Grid item md={2} xs={12}>
            <FormLabel text={incrementalImpactReport.form.audienceEvent} />
          </Grid>
          <Grid item md={10} xs={12}>
            <AudienceEventSelector />
          </Grid>
          <Grid item md={2} xs={12}>
            <FormLabel text={incrementalImpactReport.form.audienceEventType} />
          </Grid>
          <Grid item md={10} xs={12}>
            <EventTypeSelector />
          </Grid>
          <Grid item md={4} xs={8}>
            <FetchReportButton />
          </Grid>
        </Grid>
      </Box>
    </ContentContainer>
  );
}

export default ImpactReportSelector;
