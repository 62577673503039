import { Box, Grid, Icon, IconButton, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { I18nContext } from "../I18nContext";
import ContentContainer from "../components/ContentContainer";
import summaryIllustration from "../images/summary-illustration.png";
import store from "../store";
import { ultralightFont } from "../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontFamily: ultralightFont,
      margin: theme.spacing(0, 0, 4, 2),
    },
    arrowIcon: {
      padding: "0px",
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

function DashboardHeader() {
  const { translations } = useContext(I18nContext);
  const classes = useStyles();

  return (
    <ContentContainer>
      <Box p={2}>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start">
              <div>
                <img src={summaryIllustration} alt="" />
              </div>
              <Typography variant="h3" className={classes.title}>
                {translations.incrementalImpactReport.reportDetails}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            {store.impactData.reportData?.summary ? (
              <IconButton className={classes.arrowIcon} onClick={() => store.impactData.toggleFormState()} size="large">
                <Icon>{store.impactData.isFormExpanded ? "arrow_drop_up" : "arrow_drop_down"}</Icon>
              </IconButton>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </ContentContainer>
  );
}

export default DashboardHeader;
