import { Datum } from "@nivo/line";
import { ImpactReportTimeseriesData } from "shared/http/apiTypes";
import { zip } from "./array";
export interface MinMaxValues {
  min: number;
  max: number;
}
export type ImpactLineData = {
  result: { data: Datum[] };
  prediction: { yLimits: MinMaxValues; data: Datum[] };
  cumulative: { yLimits: MinMaxValues; data: Datum[] };
};
export type ImpactSeries = { event: string; data: ImpactLineData };

export const apiTimeseriesToNivoSerie = (data: ImpactReportTimeseriesData): ImpactLineData => {
  const predictionSeries = zip(data.timestamp, data.prediction, data.prediction_upper, data.prediction_lower).map(
    ([x, y, upper, lower]) => ({ x, y: Number(y), upper: Number(upper), lower: Number(lower) }),
  );

  const cumulativeSeries = zip(data.timestamp, data.cumulative, data.cumulative_upper, data.cumulative_lower).map(
    ([x, y, upper, lower]) => ({ x, y: Number(y), upper: Number(upper), lower: Number(lower) }),
  );

  return {
    result: {
      data: zip(data.timestamp, data.result).map(([x, y]) => ({ x, y: Number(y) })),
    },
    prediction: {
      data: predictionSeries,
      yLimits: getSeriesYLimits(predictionSeries),
    },
    cumulative: {
      data: cumulativeSeries,
      yLimits: getSeriesYLimits(cumulativeSeries),
    },
  };
};

export const getSeriesYLimits = (series: Datum[]): MinMaxValues =>
  series.reduce<MinMaxValues>(
    (acc, d) => {
      const yUpper = typeof d.upper === "number" ? d.upper : 0;
      const yLower = typeof d.lower === "number" ? d.lower : 0;
      return {
        max: yUpper > acc.max ? yUpper : acc.max,
        min: yLower < acc.min ? yLower : acc.min,
      };
    },
    { min: 0, max: 0 },
  );
