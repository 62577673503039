import { CircularProgress, Collapse, Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import ErrorContainer from "shared/components/ErrorContainer";
import RemergeApi from "shared/http/RemergeApi";
import { OrganizationsShowResource } from "shared/http/apiTypes";
import { ViewScreenEvent } from "shared/services/TrackingService";
import { createOrganizationSpecificEventData } from "shared/utilities/createOrganizationSpecificEventData";
import { measureDuration } from "shared/utilities/duration";
import { I18nContext } from "../../I18nContext";
import DashboardHeader from "../../components/DashboardHeader";
import ErrorOverlay from "../../components/ErrorOverlay";
import ImpactReportSelector from "../../components/ImpactReportSelector";
import LoadingOverlay from "../../components/LoadingOverlay";
import OrganizationBreadcrumb from "../../components/OrganizationBreadcrumb";
import ReportAccordion from "../../components/ReportAccordion";
import ReportHeader from "../../components/ReportHeader";
import ReportSummary from "../../components/ReportSummary";
import WithDelay from "../../components/WithDelay";
import trackingService from "../../services/tracking";
import store, { Store } from "../../store";
import { getCurrentQueryParams, updateUrlParamsFromStore } from "../../utilities/urlParams";

type Props = {
  remergeApi: RemergeApi;
  organizationId: string;
  experimentId: string;
};

function trackPageLoad(organization: OrganizationsShowResource, duration: number) {
  if (!organization?.id) return;

  trackingService.track(
    new ViewScreenEvent({
      ...createOrganizationSpecificEventData(organization),
      "Screen Type": "Index",
      "Object Type": "impact_report",
      "Screen Name": "impactReport/index",
      "Load Duration Millis": duration,
    }),
  );
}

const fetchPageData = async ({ organizationId, experimentId }: { organizationId: string; experimentId: string }) => {
  if (Object.values(store.organizations.organizations).length < 1) {
    await store.organizations.fetchOrganizations();
  }

  store.impactData.switchExperiment({ organizationId, experimentId });
  return store.impactData.fetchInterventionsAndEvents(store.organizations.audiencesByExperiment[experimentId]);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapse: {
      transition: "height 100ms cubic-bezier(.17,.8,.83,.67) 0ms",
    },
  }),
);

function IncrementalImpactReportIndex({ remergeApi, organizationId, experimentId }: Props) {
  const { translations } = useContext(I18nContext);
  const classes = useStyles();

  useEffect(() => {
    let disposeUrlWatcher: (() => void) | null = null;
    (async () => {
      const { duration } = await measureDuration(fetchPageData({ organizationId, experimentId }));
      trackPageLoad(store.organizations.organizations[organizationId], duration);
      const queryParams = getCurrentQueryParams();

      store.impactData.setURLData({ ...queryParams });
      disposeUrlWatcher = updateUrlParamsFromStore(() => ({
        selectedAudienceEventType: toJS(store.impactData.selectedAudienceEventType),
        selectedAudienceEventIds: toJS(store.impactData.selectedAudienceEventIds),
        selectedInterventionIds: toJS(store.impactData.selectedInterventionIds),
      }));
    })();

    return () => {
      if (disposeUrlWatcher !== null) {
        disposeUrlWatcher();
        disposeUrlWatcher = null;
      }
    };
  }, [organizationId, experimentId]);

  const isLoading = store.impactData.formDataLoading || store.organizations.dataLoading;

  return (
    <>
      <OrganizationBreadcrumb />
      <DashboardHeader />
      <Grid container direction="column">
        {isLoading ? (
          <Grid item md={6} xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Collapse in={store.impactData.isFormExpanded} className={classes.collapse}>
              <ImpactReportSelector remergeApi={remergeApi} organizationId={organizationId} />
            </Collapse>

            <Collapse in={!store.impactData.isFormExpanded} className={classes.collapse}>
              <ReportSummary onClick={() => store.impactData.toggleFormState()} />
            </Collapse>

            <Grid container>
              <Grid item md={12} xs={12}>
                {isErrorUnhandled(store) ? (
                  <ErrorContainer error={translations.genericError} />
                ) : (
                  <>
                    <ReportHeader timeseriesIdx={0} />
                    {!store.impactData.hasNegativeResult() && <ReportAccordion timeseriesIdx={0} />}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {(store.impactData.hasErrors() || store.organizations.hasErrors()) && <ErrorOverlay />}
      {store.impactData.dataLoading && (
        <WithDelay periodInSeconds={2}>
          <LoadingOverlay />
        </WithDelay>
      )}
    </>
  );
}

function isErrorUnhandled(store: Store | undefined): boolean {
  if (!store) return false;
  return (
    Object.keys(store.impactData.apiErrors).length > 0 &&
    Object.values(store.impactData.apiErrors).some(error => error === true)
  );
}

export default observer(IncrementalImpactReportIndex);
