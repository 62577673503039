import { Box, Grid } from "@mui/material";
import { red } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    errorContainer: {
      height: 400,
      display: "flex",
      justifyContent: "center",
    },
    error: {
      alignSelf: "center",
      color: red[400],
    },
  });

type Props = WithStyles<typeof styles> & { error: string };

function ErrorContainer({ classes, error }: Props): JSX.Element {
  return (
    <Grid item xs={12} className={classes.errorContainer}>
      <Box fontSize="h6.fontSize" fontWeight="fontWeightMedium" className={classes.error}>
        {error}
      </Box>
    </Grid>
  );
}

export default withStyles(styles)(ErrorContainer);
