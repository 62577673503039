import { CircularProgress, FormControl, Icon, InputBase, InputProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import Downshift from "downshift";
import React from "react";
import { ISelectOption, optionKey } from "shared/components/ISelectOption";
import TextField, { TextFieldProps } from "shared/components/TextField";
import LinkToAdornment from "../../components/LinkToAdornment";
import AlreadyHandledError from "../../utilities/AlreadyHandledError";
import { TranslationProps, withTranslations } from "../../utilities/I18nContext";
import Img from "../Img";
import { OptionMenu, PROGRESS_SIZE, getOptions, itemToString, removeDuplicateValues, styles } from "./Shared";

const MINIMUM_LENGTH_TO_ENABLE_CREATE_OPTION = 3;

const renderInput = (inputProps: TextFieldProps & { ref: React.Ref<HTMLDivElement> } & { isAdornment: boolean }) => {
  const { InputProps, classes, ref, isAdornment, ...other } = inputProps;

  if (!InputProps) return;

  if (isAdornment)
    return (
      <FormControl>
        <InputBase {...InputProps} inputRef={ref} />
      </FormControl>
    );

  return (
    <TextField
      InputProps={{
        ...InputProps,
        inputRef: ref,
        value: InputProps ? InputProps.value || "" : "",
      }}
      {...other}
    />
  );
};

interface CreateOption extends ISelectOption {
  create: true;
}

function isCreateOption(option: ISelectOption | CreateOption): option is CreateOption {
  return "create" in option && !!option["create"];
}

interface State {
  creation: { name: string; isRunning: true } | { isRunning: false };
}

export type IMaterialAutocompleteProps<ValueType extends ISelectOption["value"]> = Omit<
  TextFieldProps,
  "classes" | "onChange" | "value" | "defaultValue" | "InputProps" | "variant" | "onFocus"
> & {
  options: ISelectOption[];
  value: ValueType;
  onChange: (value: ValueType) => void;
  onCreate?: (value: ValueType) => Promise<ValueType>;
  InputProps?: TextFieldProps["InputProps"];
  selectedImgUrl?: string;
  selectedOptionUrl?: string;
  isAdornment?: boolean;
  isLoading?: boolean;
  showOptionsOnFocus?: boolean;
  emptyValueIsValid?: boolean;
  selectTextOnFocus?: boolean;
  disabled?: boolean;
  clearable?: boolean;
} & ({ clearable?: false; onClear?: undefined } | { clearable: true; onClear: () => void });

class MaterialAutocomplete<ValueType extends ISelectOption["value"]> extends React.Component<
  IMaterialAutocompleteProps<ValueType> & WithStyles<typeof styles, true> & TranslationProps,
  State
> {
  private popperNode: React.RefObject<HTMLDivElement>;

  constructor(props: IMaterialAutocompleteProps<ValueType> & WithStyles<typeof styles, true> & TranslationProps) {
    super(props);
    this.popperNode = React.createRef<HTMLDivElement>();
    this.state = { creation: { isRunning: false } };
  }

  public render() {
    const { onChange } = this;
    const {
      onCreate,
      onChange: unused,
      value,
      id,
      options: allOptions,
      classes,
      theme,
      InputProps = {},
      i18n,
      fullWidth,
      selectedImgUrl,
      selectedOptionUrl,
      isAdornment = false,
      isLoading = false,
      showOptionsOnFocus = false,
      emptyValueIsValid = false,
      selectTextOnFocus = false,
      disabled,
      clearable = false,
      onClear,
      ...restProps
    } = this.props;

    const options = removeDuplicateValues(allOptions);

    const { creation } = this.state;

    let isInvalidOptionSelected = false;
    let selectedItem = options.find(option => optionKey(option.value) === optionKey(value));

    if ((value || value === "") && selectedItem === undefined) {
      selectedItem = { value: String(value), label: String(value) };

      isInvalidOptionSelected = value !== "" || (!emptyValueIsValid && !clearable);
    }

    return (
      <Downshift
        id={id}
        selectedItem={selectedItem}
        onChange={onChange}
        itemToString={itemToString}
        defaultHighlightedIndex={0}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
          inputValue,
          selectedItem: currentlySelectedItem,
          highlightedIndex,
          openMenu,
          toggleMenu,
          setItemCount,
          setHighlightedIndex,
        }) => {
          const searchString = this.getSearchString(inputValue);
          let suggestedOptions = getOptions({
            query: searchString,
            options,
            selectedValues: value !== undefined ? [value] : [],
            showOptionsOnFocus,
            removeSelected: true,
          });

          if (
            onCreate &&
            searchString.length >= MINIMUM_LENGTH_TO_ENABLE_CREATE_OPTION &&
            !options.find(option => option.label === searchString)
          ) {
            const createOption = {
              label: i18n.translations.autocomplete.createOptionLabel(searchString),
              value: searchString,
              create: true,
            };
            suggestedOptions = [createOption, ...suggestedOptions];
          }

          const rowCount = suggestedOptions.length;
          setItemCount(rowCount);
          if (highlightedIndex! >= rowCount && rowCount > 0) {
            setHighlightedIndex(0);
          }

          const rowHeight = theme.typography.fontSize * 3;

          // Flag to prevent race conditions between onFocus and onClick events when toggling the menu
          let blockOpeningMenuOnFocus = false;
          const inputEventHandlers = {
            onMouseDown: () => (blockOpeningMenuOnFocus = true),
            onMouseUp: () => (blockOpeningMenuOnFocus = false),
            onClick: () => toggleMenu(),
            onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
              if (selectTextOnFocus) event.target.select();
              if (!blockOpeningMenuOnFocus) openMenu();
            },
          };

          const inputStartAdornment = selectedImgUrl ? (
            <Img className={classes.selectedImgUrl} classes={{ img: classes.selectedImg }} src={selectedImgUrl} />
          ) : undefined;

          let input;

          if (creation.isRunning || isLoading) {
            const downshiftInputProps = getInputProps({ id });
            input = renderInput({
              ...restProps,
              fullWidth,
              disabled: true,
              InputProps: {
                startAdornment: inputStartAdornment,
                endAdornment: (
                  <div className={classes.progressContainer}>
                    <CircularProgress size={PROGRESS_SIZE} />
                  </div>
                ),
                ...InputProps,
                ...downshiftInputProps,
                value: creation.isRunning ? creation.name : downshiftInputProps.value,
                ...getInputProps(inputEventHandlers),
              } as InputProps,
              ref: this.popperNode,
              isAdornment,
            });
          } else {
            input = renderInput({
              fullWidth,
              ...restProps,
              disabled,
              InputProps: {
                startAdornment: inputStartAdornment,
                endAdornment: (
                  <>
                    {value && clearable && <IconButton onClick={this.onClearIconClick}>clear</IconButton>}
                    {selectedOptionUrl && <LinkToAdornment to={selectedOptionUrl} target="_blank" position="end" />}
                  </>
                ),
                ...InputProps,
                ...getInputProps({ id, ...inputEventHandlers }),
                error: InputProps.error || isInvalidOptionSelected,
              } as InputProps,
              ref: this.popperNode,
              isAdornment,
            });
          }

          return (
            <div className={fullWidth ? classes.fullWidthContainer : classes.container}>
              {input}
              {!disabled && (
                <OptionMenu
                  {...{
                    isOpen,
                    rowCount,
                    rowHeight,
                    highlightedIndex,
                    getItemProps,
                    getMenuProps,
                    anchorNode: this.popperNode.current,
                    selectedItemKey: currentlySelectedItem,
                    options: suggestedOptions,
                  }}
                />
              )}
            </div>
          );
        }}
      </Downshift>
    );
  }

  private onClearIconClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>): void => {
    if (this.props.clearable) this.props.onClear();
    event.stopPropagation();
  };

  private getSearchString = (inputValue: string | null) => {
    if (!inputValue) return "";

    const { value: selectedValue, options } = this.props;

    const selectedValueLabel = options.find(option => option.value.toString() === selectedValue?.toString())?.label;
    if (selectedValueLabel === inputValue) return "";

    return inputValue;
  };

  private onChange = async (selectedOption: ISelectOption | CreateOption) => {
    const { onChange, onCreate } = this.props;
    let value = selectedOption.value;
    if (onCreate && isCreateOption(selectedOption)) {
      this.setState({ creation: { name: optionKey(value).toString(), isRunning: true } });
      try {
        value = await onCreate(value as ValueType);
      } catch (error: unknown) {
        if (!(error instanceof AlreadyHandledError)) throw error;
      } finally {
        this.setState({ creation: { isRunning: false } });
      }
    }
    onChange(value as ValueType);
  };
}

const IconButton = styled(Icon)({
  "&:hover": {
    cursor: "pointer",
  },
});

export default withTranslations(withStyles(styles, { withTheme: true })(MaterialAutocomplete));
