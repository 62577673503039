import Cookie from "js-cookie";
import { EmployeeInfo } from "./apiTypes";

const ID_TOKEN_COOKIE_NAME = "id_token";

export type IdTokenUser = {
  id: string;
  email: string;
  name: string;
  organizations: number[];
  detailed_report_organizations: number[];
} & (({ admin: true } & EmployeeInfo) | { admin: false });

export interface IdToken {
  exp: number;
  user: IdTokenUser;
  csrf_token: string;
}

export function idTokenHeader(): string {
  const jwt = Cookie.get(ID_TOKEN_COOKIE_NAME);

  if (!jwt) return "";

  return `${ID_TOKEN_COOKIE_NAME}=${jwt}`;
}

export function parseIdToken(): IdToken | undefined {
  const jwt = Cookie.get(ID_TOKEN_COOKIE_NAME);

  if (!jwt) return;

  const [, body] = jwt.split(".");

  try {
    return JSON.parse(atob(body)) as IdToken;
  } catch (error: unknown) {
    console.error("Error decoding IdToken:", error);
    return;
  }
}

export function removeIdToken() {
  Cookie.remove(ID_TOKEN_COOKIE_NAME, { path: "/", domain: import.meta.env.VITE_SSO_COOKIE_DOMAIN });
}
