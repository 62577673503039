import { Accordion, AccordionDetails, AccordionSummary, Box, Grid } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import { I18nContext } from "../../I18nContext";
import store from "../../store";
import { black, olive, violet, white } from "../../theme";
import ContentContainer from "../ContentContainer";
import AdditionalDetails from "./AdditionalDetails";
import ImpactGraphs from "./ImpactGraphs";
import KeyResults from "./KeyResults";
import OurObjective from "./OurObjective";
import TitleLine from "./TitleLine";

type Props = {
  timeseriesIdx: number;
};

enum PanelType {
  KeyResults = "KeyResults",
  AdditionalDetails = "AdditionalDetails",
  DataViz = "DataViz",
  Objective = "Objective",
}

const CustomAccordion = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
    margin: 0,
  },
  expanded: {},
})(Accordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordion: {
      border: 0,
      boxShadow: "none",
      "&:before": {
        display: "none",
      },
      margin: 0,
    },
    section: {
      width: "100%",
      padding: theme.spacing(2, 0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(2, 4),
      },
    },
    summarySection: {
      "&:hover": {
        color: white,
        backgroundColor: black,
      },
    },
    whiteBg: {
      backgroundColor: white,
    },
    violetBg: {
      backgroundColor: violet.extraLight,
    },
    oliveBg: {
      backgroundColor: olive.main,
    },
    description: {
      color: olive.dark,
    },
    content: {
      marginLeft: "auto",
      marginRight: "0",
    },
  }),
);

function ReportAccordion({ timeseriesIdx }: Props) {
  const classes = useStyles();

  const {
    translations: { incrementalImpactReport: I18n },
  } = useContext(I18nContext);

  const [expanded, setExpanded] = useState<PanelType | false>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChange = (panel: PanelType) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const {
    impactData: { reportData: summaries },
  } = store;

  const summary = (summaries?.summary || [])[timeseriesIdx];

  if (!summary) return null;

  return (
    <>
      <Grid item className={classes.whiteBg}>
        <CustomAccordion
          className={classes.accordion}
          expanded={expanded === PanelType.KeyResults}
          onChange={handleChange(PanelType.KeyResults)}
        >
          <AccordionSummary
            aria-controls="key-results-content"
            id="key-results-header"
            className={classes.summarySection}
          >
            <ContentContainer>
              <Box className={classes.section}>
                <TitleLine sectionNumber={1} title={I18n.sections.keyResults.title} />
              </Box>
            </ContentContainer>
          </AccordionSummary>
          <AccordionDetails>
            <ContentContainer>
              <Box className={classes.section}>
                <Grid item md={11} xs={10} className={classes.content}>
                  <KeyResults timeseriesIdx={0} />
                </Grid>
              </Box>
            </ContentContainer>
          </AccordionDetails>
        </CustomAccordion>
      </Grid>
      <Grid item className={classes.violetBg}>
        <CustomAccordion
          expanded={expanded === PanelType.AdditionalDetails}
          onChange={handleChange(PanelType.AdditionalDetails)}
          className={classnames(classes.accordion, classes.violetBg)}
        >
          <AccordionSummary
            aria-controls="key-results-content"
            id="key-results-header"
            className={classes.summarySection}
          >
            <ContentContainer>
              <Box className={classes.section}>
                <TitleLine sectionNumber={2} title={I18n.sections.additionalDetails.title} />
              </Box>
            </ContentContainer>
          </AccordionSummary>
          <AccordionDetails>
            <ContentContainer>
              <Box className={classes.section}>
                <Grid item md={11} xs={10} className={classes.content}>
                  <AdditionalDetails timeseriesIdx={0} />
                </Grid>
              </Box>
            </ContentContainer>
          </AccordionDetails>
        </CustomAccordion>
      </Grid>
      <Grid item className={classes.whiteBg}>
        <CustomAccordion
          className={classes.accordion}
          expanded={expanded === PanelType.DataViz}
          onChange={handleChange(PanelType.DataViz)}
        >
          <AccordionSummary
            aria-controls="key-results-content"
            id="key-results-header"
            className={classes.summarySection}
          >
            <ContentContainer>
              <Box className={classes.section}>
                <TitleLine sectionNumber={3} title={I18n.sections.dataViz.title} />
              </Box>
            </ContentContainer>
          </AccordionSummary>
          <AccordionDetails>
            <ContentContainer>
              <Box className={classes.section}>
                <ImpactGraphs />
              </Box>
            </ContentContainer>
          </AccordionDetails>
        </CustomAccordion>
      </Grid>
      <Grid item className={classes.oliveBg}>
        <CustomAccordion
          expanded={expanded === PanelType.Objective}
          onChange={handleChange(PanelType.Objective)}
          className={classnames(classes.accordion, classes.oliveBg)}
        >
          <AccordionSummary
            aria-controls="key-results-content"
            id="key-results-header"
            className={classes.summarySection}
          >
            <ContentContainer>
              <Box className={classes.section}>
                <TitleLine sectionNumber={4} title={I18n.sections.objective.title} />
              </Box>
            </ContentContainer>
          </AccordionSummary>
          <AccordionDetails>
            <ContentContainer>
              <Box className={classes.section}>
                <Grid item md={11} xs={10} className={classes.content}>
                  <OurObjective />
                </Grid>
              </Box>
            </ContentContainer>
          </AccordionDetails>
        </CustomAccordion>
      </Grid>
    </>
  );
}

export default observer(ReportAccordion);
