import { Box, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import { black, olive } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legendItem: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space between",
      alignItems: "center",
    },
    linePoint: {
      width: 20,
      marginRight: theme.spacing(2),
    },
    dashedLine: {
      height: 10,
      borderTop: `2px dashed ${olive.main}`,
    },
    solidOliveLine: {
      height: 10,
      borderTop: `2px solid ${olive.main}`,
    },
    solidBlackLine: {
      height: 10,
      borderTop: `2px solid ${black}`,
    },
    solidPoint: {
      height: 20,
      backgroundColor: olive.main,
    },
    label: {
      width: 80,
      lineHeight: 0.9,
    },
  }),
);
function GraphLegendItem({
  variant,
  children,
}: {
  children: string;
  variant: "dashedLine" | "solidBlackLine" | "solidOliveLine" | "solidPoint";
}) {
  const classes = useStyles();
  return (
    <Grid item md={2} xs={4}>
      <Box className={classes.legendItem}>
        <Box className={classnames(classes.linePoint, classes[variant])}></Box>
        <Typography variant="caption" className={classes.label}>
          {children}
        </Typography>
      </Box>
    </Grid>
  );
}
export default GraphLegendItem;
