import mapValues from "lodash/mapValues";
import { addHeaders, createFetch, prependHost } from "micro-http-client";
import objectToFormData from "object-to-formdata";
import { parseIdToken } from "shared/http/id-token";
import { IDocumentWithErrors, PersistedResourceObject } from "shared/http/jsonApi";

// tslint:disable max-classes-per-file

export class UnauthenticatedError extends Error {
  constructor() {
    super("Unauthenticated");

    Object.setPrototypeOf(this, UnauthenticatedError.prototype);
  }
}

function parseResponse(response: Response): object | null {
  if (response.status === 204) {
    return null;
  }

  return response.json();
}

const ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-](\d{2}):(\d{2}))$/;
const UTC_MIDNIGHT_SUFFIX = "T00:00:00.000Z";

function parseDateStringToDate(value: any): any {
  if (!value) return value;

  if (typeof value === "string" && value.match(ISO_8601_REGEX)) {
    return new Date(value as string);
  } else if (typeof value === "object" && Object.keys(value).length === 1 && value.date) {
    return new Date(value.date + UTC_MIDNIGHT_SUFFIX);
  }

  return value;
}

function parseAttributes(resource: PersistedResourceObject<any, any>): PersistedResourceObject<any, any> {
  const attributes = mapValues(resource.attributes, parseDateStringToDate);
  return { ...resource, attributes };
}

function isBatchUpdateResponse(response: any) {
  return response && Array.isArray(response) && (response[0].data || response[0].errors);
}

function parseJsonAPIDates(response: any) {
  if (isBatchUpdateResponse(response)) {
    return response.map(parseJsonAPIDates);
  }

  if (response?.data) {
    let data = response.data;
    if (data instanceof Array) {
      data = data.map(resource => (resource.attributes ? parseAttributes(resource) : resource));
    } else {
      data = parseAttributes(data);
    }

    return { ...response, data };
  }

  return response;
}

function normalizeJsonApiObject(jsonApiObject: any) {
  let normalizedObject = { ...jsonApiObject };

  if (jsonApiObject.attributes) {
    normalizedObject = {
      ...normalizedObject,
      attributes: mapValues(jsonApiObject.attributes, (value: any) => (value === undefined ? null : value)),
    };
  }

  if (jsonApiObject.relationships) {
    normalizedObject = {
      ...normalizedObject,
      relationships: mapValues(jsonApiObject.relationships, (value: any) => (value === undefined ? null : value)),
    };
  }

  return normalizedObject;
}

function normalizeJsonApiRequestBody(body: any) {
  if (body && body.data) {
    let data = body.data;
    if (data instanceof Array) {
      data = data.map(normalizeJsonApiObject);
    } else {
      data = normalizeJsonApiObject(data);
    }

    return { ...body, data };
  }

  return body;
}

function containsFileObjects(objectOrArray: any): boolean {
  if (!objectOrArray || typeof objectOrArray !== "object") return false;
  if (objectOrArray instanceof Array) {
    return objectOrArray.some(containsFileObjects);
  }
  if (objectOrArray instanceof File) return true;
  return containsFileObjects(Object.values(objectOrArray));
}

function processRequestBody(request: object): any {
  // @ts-expect-error
  const body = request["body"];
  if (!body) return request;

  if (containsFileObjects(body)) {
    const multipartBody = objectToFormData(body);
    // @ts-expect-error
    const newHeaders = { ...request["headers"] };
    delete newHeaders["Content-Type"];
    return { ...request, headers: newHeaders, body: multipartBody };
  }

  const jsonApiBody = JSON.stringify(normalizeJsonApiRequestBody(body));
  return { ...request, body: jsonApiBody };
}

export interface IJsonApiErrorResponse {
  status: number;
  body: IDocumentWithErrors;
}

export class JsonApiError extends Error {
  constructor(public readonly response: IJsonApiErrorResponse) {
    super(`Response with status code ${response.status} received`);
  }
}

export interface IHttpErrorResponse {
  body: any;
  status: number;
  url: string;
}
export class HttpError extends Error {
  constructor(public readonly response: IHttpErrorResponse) {
    super(`Response with status code ${response.status} received`);
  }
}

export function isHttpError(value: unknown): value is HttpError {
  return value instanceof HttpError;
}

async function processJsonApiErrors(response: Response): Promise<Response> {
  if (response.status >= 200 && response.status < 400) {
    return response;
  }

  const bodyText = await response.text();

  let body;
  try {
    body = JSON.parse(bodyText);
  } catch {
    body = bodyText;
  }

  if (body && body.errors) {
    throw new JsonApiError({ body: body as IDocumentWithErrors, status: response.status });
  }

  throw new HttpError({ body, status: response.status, url: response.url });
}

export interface IHttpService {
  fetch(url: string, options?: object): Promise<any>;
}

function includeCredentials(request: RequestInit): RequestInit {
  return { ...request, credentials: "include" };
}

export default class HttpService {
  public fetch: (url: string, requestOptions: object) => Promise<any>;

  constructor(apiHost: string) {
    this.fetch = createFetch({
      requestReducers: [
        includeCredentials,
        prependHost(apiHost),
        addHeaders({
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        }),
        addHeaders(() => {
          const idToken = parseIdToken();
          if (idToken) {
            return {
              "X-CSRF-TOKEN": idToken.csrf_token,
            };
          }
          return undefined;
        }),
        processRequestBody,
      ],
      responseReducers: [this.notifyUnauthorizedResponse, processJsonApiErrors, parseResponse, parseJsonAPIDates],
    });
  }

  private notifyUnauthorizedResponse = (response: Response) => {
    if (response.status === 401) {
      throw new UnauthenticatedError();
    }
    return response;
  };
}
