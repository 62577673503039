import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import store from "../../store";
import { ImpactSeries } from "../../utilities/impactData";
import HeaderButton from "../HeaderButton";
import { downloadReport } from "../ReportHeader";
import GraphLegendItem from "./GraphLegendItem";
import ImpactGraph from "./ImpactGraph";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      fontSize: 16,
      marginLeft: "auto",
      marginRight: "0",
    },
    content: {
      marginLeft: "auto",
      marginRight: "0",
    },
    buttonContainer: {
      marginTop: theme.spacing(6),
    },
    selectedEvent: {
      position: "absolute",
      top: theme.spacing(11),
    },
  }),
);

function ImpactGraphs() {
  const { translations } = useContext(I18nContext);
  const classes = useStyles();
  if (store.impactData.reportData === null) return null;

  const selectedEvents = store.impactData.selectedAudienceEventNames()?.join(", ");

  return (
    <>
      <Grid container justifyContent="flex-start" md={6}>
        <Grid item xs={10} className={classes.description}>
          <Typography variant="h6" className={classes.selectedEvent}>
            {selectedEvents}
          </Typography>
          {translations.incrementalImpactReport.sections.dataViz.description}
        </Grid>
      </Grid>
      {store.impactData.reportData?.dataSeries?.map((dataItem: ImpactSeries) => {
        const graphLines = {
          prediction: [
            { id: "result", data: dataItem.data.result.data },
            { id: "prediction", data: dataItem.data.prediction.data },
          ],
        };

        return (
          <div key={dataItem.event}>
            <Grid item md={11} xs={10} className={classes.content}>
              <ImpactGraph
                data={graphLines.prediction}
                areaSeriesId="prediction"
                yLimits={dataItem.data.prediction.yLimits}
              />
              <Grid container spacing={2}>
                <GraphLegendItem variant="dashedLine">{translations.graph.legend.organic}</GraphLegendItem>
                <GraphLegendItem variant="solidBlackLine">{translations.graph.legend.measured}</GraphLegendItem>
                <GraphLegendItem variant="solidPoint">{translations.graph.legend.interval}</GraphLegendItem>
              </Grid>
              <div className={classes.buttonContainer}>
                <HeaderButton onClick={downloadReport}>
                  {translations.incrementalImpactReport.form.downloadReportButton}
                </HeaderButton>
              </div>
            </Grid>
          </div>
        );
      })}
    </>
  );
}

export default observer(ImpactGraphs);
