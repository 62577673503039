const UTF16_BYTE_ORDER_MARK = 0xfeff;

const MIME_TYPE = {
  csv: "text/csv,",
};

type MimeType = keyof typeof MIME_TYPE;

function convertToUTF16(str: string) {
  const buffer = new ArrayBuffer((str.length + 1) * 2);
  const bufferView = new Uint16Array(buffer);
  bufferView[0] = UTF16_BYTE_ORDER_MARK;

  for (let i = 1; i <= str.length; i += 1) {
    bufferView[i] = str.charCodeAt(i - 1);
  }

  return buffer;
}

export default function triggerFileDownload(type: MimeType, filename: string, content: string) {
  const mimeType = MIME_TYPE[type];

  const blob = new Blob([convertToUTF16(content)], { type: `${mimeType};charset=UTF-16` });
  const uri = URL.createObjectURL(blob);

  triggerFileDownloadFromLink(uri, filename);
  URL.revokeObjectURL(uri);
}

export function triggerFileDownloadFromLink(uri: string, filename: string) {
  const link = document.createElement("a");
  link.setAttribute("href", uri);
  link.setAttribute("download", filename);
  document.body.appendChild(link); // Needed for Firefox to work
  link.click();
  document.body.removeChild(link);
}
