import { makeAutoObservable } from "mobx";
import { OrganizationsIndexResource } from "shared/http/apiTypes";
import { ExperimentResource } from "shared/http/apiTypes/experiment";
import { data } from "shared/http/jsonApi";
import remergeApi from "../utilities/remergeApi";

type ApiResource = "experiments" | "organization";

class OrganizationsStore {
  apiErrors: { [key in ApiResource]?: boolean };
  organizations: Record<string, OrganizationsIndexResource>;
  experimentsByOrg: Record<string, ExperimentResource[]>;
  dataLoading: boolean;
  audiencesByExperiment: Record<string, string>;

  constructor() {
    this.reset();
    makeAutoObservable(this);
  }

  public reset() {
    this.dataLoading = false;
    this.organizations = {};
    this.experimentsByOrg = {};
    this.apiErrors = {};
  }

  public hasErrors() {
    return Object.values(this.apiErrors).some(errors => errors);
  }

  public async fetchOrganizations() {
    return this.wrapApiCall<void>("organization", async () => {
      const experiments = await data(remergeApi.experimentsIndex({ experiment_type: ["impact_experiment"] }));
      this.experimentsByOrg = experiments.reduce<Record<string, ExperimentResource[]>>((acc, experiment) => {
        let orgId = experiment.relationships.organization.data.id;
        if (!acc[orgId]) acc[orgId] = [];
        acc[orgId].push(experiment);
        return acc;
      }, {});

      this.audiencesByExperiment = experiments.reduce<Record<string, string>>((acc, experiment) => {
        let audienceId = experiment.relationships.audience.data.id;
        acc[experiment.id] = audienceId;
        return acc;
      }, {});

      const organizations = await data(remergeApi.organizationsIndex({ ids: Object.keys(this.experimentsByOrg) }));
      this.organizations = organizations.reduce<Record<string, OrganizationsIndexResource>>(
        (acc, org) => ({
          ...acc,
          [org.id]: org,
        }),
        {},
      );
    });
  }

  protected async wrapApiCall<ResponseData>(
    apiResource: ApiResource,
    call: () => Promise<ResponseData>,
  ): Promise<ResponseData | null> {
    this.dataLoading = true;
    this.apiErrors[apiResource] = false;
    try {
      return await call();
    } catch (error) {
      this.apiErrors[apiResource] = true;
      throw error;
    } finally {
      this.dataLoading = false;
    }
  }
}

export default OrganizationsStore;
