import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import circleImage from "../../images/circle-around.png";
import leftBracketImage from "../../images/left-pencil-bracket.png";
import rightBracketImage from "../../images/right-pencil-bracket.png";
import { black, importantValueFontSize, subHeadlineFontSize } from "../../theme";

type Props = {
  mainValue: string;
  topLabel: string;
  bottomLabel: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: theme.spacing(2),
    },
    fraction: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    fractionText: {
      fontSize: subHeadlineFontSize,
    },
    mainValue: {
      margin: theme.spacing(3),
      fontSize: importantValueFontSize,
    },
    horizontalBar: {
      backgroundColor: black,
      height: 2,
      margin: theme.spacing(1, 0),
      width: "110%",
    },
    backgroundImage: {
      backgroundImage: `url(${circleImage})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    equationWithBrackets: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  }),
);

function FractionValue({ mainValue, topLabel, bottomLabel }: Props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.backgroundImage}>
        <Typography variant="h2" className={classes.mainValue}>
          {mainValue}
        </Typography>
      </Box>
      <Box mt={2} className={classes.equationWithBrackets}>
        <img src={leftBracketImage} alt="" />
        <div>
          <Typography className={classes.fractionText} variant="h5">
            {topLabel}
          </Typography>
          <div className={classes.horizontalBar}></div>
          <Typography className={classes.fractionText} variant="h5">
            {bottomLabel}
          </Typography>
        </div>
        <img src={rightBracketImage} alt="" />
      </Box>
    </Box>
  );
}

export default FractionValue;
