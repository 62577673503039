import React from "react";
import { FieldProps } from "shared/components/form/FieldProps";
import { ISelectOption } from "shared/components/ISelectOption";
import { IMaterialAutocompleteProps, MaterialAutocomplete } from "shared/components/MaterialAutocomplete";

interface MaterialAutocompleteFieldProps<ValueType extends ISelectOption["value"]>
  extends FieldProps<ValueType, IMaterialAutocompleteProps<ValueType>> {}

export default class MaterialAutocompleteField<ValueType extends ISelectOption["value"]> extends React.PureComponent<
  MaterialAutocompleteFieldProps<ValueType>
> {
  public render() {
    const { errors, ...restProps } = this.props;
    const hasError = errors.length > 0;

    return (
      <MaterialAutocomplete {...restProps} error={hasError} helperText={errors.map(error => error.detail).join(", ")} />
    );
  }
}
