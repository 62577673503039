import { CustomLayerProps } from "@nivo/line";
import { black, veryDarkGrey } from "../../theme";

const LINE_STYLES = {
  default: {
    stroke: black,
    strokeWidth: 2,
    strokeLength: 1,
  },
  prediction: {
    strokeDasharray: "6",
    strokeWidth: 2,
    stroke: veryDarkGrey,
  },
};

const StyledLines = ({ series, lineGenerator, xScale, yScale }: CustomLayerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return series.map(({ id, data, color }) => (
    <path
      key={id}
      d={lineGenerator(
        data.map(d => ({
          x: d.data.x ? xScale(d.data.x) : null,
          y: d.data.y ? yScale(d.data.y) : null,
        })),
      )}
      fill="none"
      // @ts-expect-error
      style={LINE_STYLES[id] || LINE_STYLES.default}
    />
  ));
};

export default StyledLines;
