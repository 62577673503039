import { Link, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { Link as RouterLink } from "wouter";
import { I18nContext } from "../I18nContext";
import { ultralightFont } from "../theme";
import PageContainer from "./PageContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: { textAlign: "center", textTransform: "uppercase" },
    content: {
      fontFamily: ultralightFont,
      textAlign: "center",
      fontSize: theme.typography.fontSize * 3,
      color: theme.palette.text.primary,
    },
  }),
);

export function NotFound() {
  const i18n = useContext(I18nContext);

  const classes = useStyles();

  return (
    <PageContainer>
      <h2 className={classes.title}>{i18n.translations.notFound.title}</h2>
      <Typography variant="body1" className={classes.content}>
        {i18n.translations.notFound.content1}
        <RouterLink to="/organizations">
          <Link>{i18n.translations.notFound.experimentSelection}</Link>
        </RouterLink>
        {i18n.translations.notFound.content2}
        <Link href={`https://${i18n.translations.remergeHomepage}`}>{i18n.translations.notFound.homepage}</Link>
      </Typography>
    </PageContainer>
  );
}
