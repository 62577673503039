import { autorun } from "mobx";
import queryString, { ParsedQuery } from "query-string";

export const getCurrentQueryParams = () => queryString.parse(window.location.search, { arrayFormat: "comma" });

export const generateNewQueryParams = (
  currentParamsFromStore: Record<string, string | string[] | boolean>,
  currentQueryParams: ParsedQuery<string>,
): { needsUpdate: false } | { needsUpdate: true; newQueryString: string } => {
  const shouldUpdateUrl =
    queryString.stringify(removeEmptyValues(currentParamsFromStore)) !==
    queryString.stringify(removeEmptyValues(currentQueryParams));

  if (!shouldUpdateUrl) return { needsUpdate: false };

  return {
    needsUpdate: true,
    newQueryString: queryString.stringify(currentParamsFromStore, { arrayFormat: "comma" }),
  };
};

export const updateUrlParamsFromStore = (getParamsFromStore: () => Record<string, string | string[] | boolean>) =>
  autorun(() => {
    const paramsFromStore = getParamsFromStore();
    const url = generateNewQueryParams(paramsFromStore, queryString.parse(document.location.search));
    if (!url.needsUpdate) {
      return;
    }

    window.history.replaceState({}, "", `${document.location.pathname}?${url.newQueryString}`);
  });

function removeEmptyValues(object: Object) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length === 0) return acc;
    if (!value) return acc;
    // @ts-expect-error
    acc[key] = value;

    return acc;
  }, {});
}
