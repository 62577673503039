import { Popover, PopoverProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    previewImage: {
      display: "block",
      width: "auto",
      height: "auto",
    },
    hoverImage: {
      display: "block",
      maxWidth: 400,
      maxHeight: 400,
    },
  });

interface Props
  extends WithStyles<typeof styles>,
    Omit<
      React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
      "onMouseOver" | "onMouseLeave"
    > {
  popoverProps?: Partial<PopoverProps>;
  maxWidth: number;
  maxHeight: number;
  imgClassName?: string;
}

interface State {
  popoverOpen: boolean;
}

class ImageWithPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      popoverOpen: false,
    };
    this.imgRef = React.createRef();
  }

  public render() {
    const { getPopoverAnchorElement, onMouseOver, onMouseLeave, imgRef } = this;
    const { classes, className, popoverProps, maxWidth, maxHeight, imgClassName, ...imgProps } = this.props;
    const { popoverOpen } = this.state;

    return (
      <div className={className}>
        <img
          alt=""
          {...imgProps}
          {...{ onMouseOver, onMouseLeave }}
          className={classNames(classes.previewImage, imgClassName)}
          ref={imgRef}
          style={{ maxWidth, maxHeight }}
        />

        <Popover
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          disableRestoreFocus
          className={classes.popover}
          {...popoverProps}
          open={popoverOpen}
          onClose={onMouseLeave}
          anchorEl={getPopoverAnchorElement}
        >
          <img alt="" className={classes.hoverImage} {...imgProps} />
        </Popover>
      </div>
    );
  }

  private imgRef: React.RefObject<HTMLImageElement>;
  private onMouseOver = (): void => {
    this.setState({ popoverOpen: true });
  };
  private onMouseLeave = (): void => {
    this.setState({ popoverOpen: false });
  };

  private getPopoverAnchorElement = () => {
    return this.imgRef.current!;
  };
}

export default withStyles(styles)(ImageWithPreview);
