import { LinearProgress } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import React from "react";
import Button, { ButtonProps } from "./LegacyButton";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => {
  return createStyles({
    button: {
      position: "relative",
    },
    progress: {
      position: "absolute",
      borderRadius: "4px 4px 0 0",
      top: 0,
      left: 0,
      right: 0,
      height: 3,
    },
  });
};

interface UncontrolledProps extends Omit<ButtonProps, "onClick" | "classes">, WithStyles<typeof styles> {
  isRunning?: undefined;
  onClick: () => Promise<unknown>;
}

interface ControlledProps extends Omit<ButtonProps, "onClick" | "classes">, WithStyles<typeof styles> {
  onClick?: () => void;
  isRunning: boolean;
}

type Props = UncontrolledProps | ControlledProps;

interface State {
  internalIsRunning: boolean;
}

class ButtonWithProgress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { internalIsRunning: false };
  }

  render() {
    const { disabled, isRunning: controlledIsRunning, onClick, children, classes, ...restProps } = this.props;
    const { internalIsRunning } = this.state;

    const isRunning = controlledIsRunning !== undefined ? controlledIsRunning : internalIsRunning;

    return (
      <Button
        color="primary"
        variant="contained"
        {...restProps}
        disabled={isRunning || disabled}
        onClick={this.onClick}
      >
        {children}
        {isRunning && <LinearProgress className={classes.progress} />}
      </Button>
    );
  }

  private onClick = async () => {
    const { onClick, isRunning: controlledIsRunning } = this.props;

    if (!onClick) {
      return;
    }

    try {
      this.setState({ internalIsRunning: true });
      await onClick();
    } finally {
      if (controlledIsRunning === undefined) {
        this.setState({ internalIsRunning: false });
      }
    }
  };
}

export default withStyles(styles)(ButtonWithProgress);
