import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { I18nContext } from "../I18nContext";
import { black, ultralightFont } from "../theme";

function reloadPage() {
  window.location.reload();
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      border: `1 solid ${black}`,
      borderRadius: 0,
      padding: theme.spacing(2),
    },
    title: { textAlign: "center", textTransform: "uppercase" },
    content: {
      fontFamily: ultralightFont,
      textAlign: "center",
      fontSize: theme.typography.fontSize * 2.5,
      color: theme.palette.text.primary,
    },
  }),
);

function ErrorOverlay() {
  const i18n = useContext(I18nContext);
  const classes = useStyles();

  const supportEmail = i18n.translations.supportEmail;

  return (
    <Dialog open onClose={reloadPage} PaperProps={{ className: classes.container }}>
      <DialogTitle className={classes.title}>{i18n.translations.errorOverlay.title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.content}>
          {i18n.translations.errorOverlay.content}
          <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={reloadPage}>{i18n.translations.errorOverlay.reloadButton}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorOverlay;
