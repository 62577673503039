import { Box, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { CopyReportSummaryToClipboardEvent, DownloadImpactReportEvent } from "shared/services/TrackingService";
import copyToClipboard from "shared/utilities/form/copyToClipboard";
import { I18nContext } from "../I18nContext";
import footerIllustration from "../images/footer-illustration.png";
import trackingService from "../services/tracking";
import store from "../store";
import { headlineFont, olive, ultralightFont } from "../theme";
import ContentContainer from "./ContentContainer";
import HeaderButton from "./HeaderButton";
import { getValueLabel } from "./ReportAccordion/ScaleValue";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: olive.main,
      padding: theme.spacing(4, 2),
    },
    description: {
      [theme.breakpoints.down("lg")]: {
        fontSize: theme.typography.h3.fontSize,
      },
    },
    bold: {
      fontWeight: 400,
      fontFamily: headlineFont,
    },
    ultralight: {
      fontFamily: ultralightFont,
    },
  }),
);

type Props = {
  timeseriesIdx: number;
};

function BoldText({ text }: { text: string | number }) {
  const classes = useStyles();

  return <span className={classes.bold}>{text}</span>;
}

function LightText({ text }: { text: string | number }) {
  const classes = useStyles();

  return <span className={classes.ultralight}>{text}</span>;
}

export const downloadReport = () => {
  store.impactData.downloadReport();

  trackingService.track(
    new DownloadImpactReportEvent({
      "Experiment Id": store.impactData.experimentId,
      "Event Ids": toJS(store.impactData.selectedAudienceEventIds),
      "Intervention Ids": toJS(store.impactData.selectedInterventionIds),
      "Organization Id": store.impactData.organizationId || "",
    }),
  );
};

const copyTextToClipboard = (value: string) => {
  copyToClipboard(value);

  trackingService.track(
    new CopyReportSummaryToClipboardEvent({
      "Experiment Id": store.impactData.experimentId,
      "Event Ids": toJS(store.impactData.selectedAudienceEventIds),
      "Intervention Ids": toJS(store.impactData.selectedInterventionIds),
      "Organization Id": store.impactData.organizationId || "",
    }),
  );
};

function ReportHeader({ timeseriesIdx }: Props) {
  const {
    translations: { incrementalImpactReport: translations },
  } = useContext(I18nContext);
  const i18n = useContext(I18nContext);
  const classes = useStyles();

  const {
    organizations: { organizations, experimentsByOrg },
    impactData: { organizationId, experimentId, reportData: summaries },
  } = store;

  const selectedOrg = organizations[organizationId];
  const summary = (summaries?.summary || [])[timeseriesIdx];

  if (!selectedOrg || !summary) return null;

  const selectedOrgExperiments = experimentsByOrg[selectedOrg.id] || [];
  const selectedExperiment = selectedOrgExperiments.find(experiment => experiment.id === experimentId);

  if (!selectedOrg || !selectedExperiment) return null;
  const spend = (summary.advertiser_cost / summary.incremental_events.mean).toFixed(2);

  const percentage = summary.greater_than_zero_percent * 100;
  const confidenceLabel = getValueLabel(percentage, translations.scale.percentage);

  const summaryText = store.impactData.hasNegativeResult() ? (
    translations.header.negativeResult
  ) : (
    <>
      {translations.header.shows} <BoldText text={i18n.formatNumber(summary.incremental_events.mean)} />{" "}
      {translations.header.eventsWithEffect}{" "}
      <BoldText text={i18n.formatNumber(summary.relative_effect.mean, "percentWithTwoDecimalDigits")} />
      {` ${translations.header.at} `}
      <BoldText text={`€ ${i18n.formatNumber(parseFloat(spend), "money")}`} /> {translations.header.perEventWithA}{" "}
      <BoldText text={confidenceLabel.toLowerCase()} /> {translations.header.confidenceRate}
    </>
  );

  const summaryToClipboard = `${translations.header.shows} ${i18n.formatNumber(summary.incremental_events.mean)} ${
    translations.header.eventsWithEffect
  } ${i18n.formatNumber(summary.relative_effect.mean, "percentWithTwoDecimalDigits")} ${
    translations.header.at
  } € ${i18n.formatNumber(parseFloat(spend), "money")} ${
    translations.header.perEventWithA
  } ${confidenceLabel.toLowerCase()} ${translations.header.confidenceRate}`;

  return (
    <Grid className={classes.root}>
      <ContentContainer>
        <Box m={2}>
          <Grid item xs={12}>
            <Box mb={3}>
              <Typography variant="h4" component="span">
                {translations.title}
                <LightText text={` ${translations.header.for} ${selectedOrg.attributes.name}`} />
              </Typography>
            </Box>
            <Typography variant="h2" className={classNames(classes.ultralight, classes.description)}>
              {summaryText}
            </Typography>
          </Grid>
        </Box>
        <Grid container justifyContent="space-between">
          <Grid item>
            {store.impactData.hasNegativeResult() ? null : (
              <Grid container justifyContent="space-between" alignItems="center">
                <HeaderButton onClick={downloadReport}>{translations.form.downloadReportButton}</HeaderButton>
                <HeaderButton onClick={() => copyTextToClipboard(summaryToClipboard)}>
                  {translations.form.copyToClipboardButton}
                </HeaderButton>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <img src={footerIllustration} alt={translations.footerImgAlt} />
          </Grid>
        </Grid>
      </ContentContainer>
    </Grid>
  );
}

export default observer(ReportHeader);
