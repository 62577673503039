import { Icon, InputAdornment, InputAdornmentProps } from "@mui/material";
import LegacyIconButtonLink from "./LegacyIconButtonLink";

export interface LinkToAdornmentProps extends Pick<InputAdornmentProps, "position"> {
  to: string;
  target?: string;
}

function LinkToAdornment({ to, target, position }: LinkToAdornmentProps) {
  return (
    <InputAdornment position={position}>
      <LegacyIconButtonLink to={to} target={target}>
        <Icon>open_in_new</Icon>
      </LegacyIconButtonLink>
    </InputAdornment>
  );
}

export default LinkToAdornment;
