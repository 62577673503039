import { Dialog, DialogContent, Icon, IconButton, Typography, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useContext, useState } from "react";
import { I18nContext } from "../I18nContext";
import preLoaderScreen from "../images/Pre-Loader_Screen.gif";
import { black, ultralightFont } from "../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxWidth: "800px",
      width: "40vw",
      border: `1px solid ${black}`,
      borderRadius: 0,
      padding: theme.spacing(10, 0),
      [theme.breakpoints.down("xl")]: {
        width: "70vw",
      },
    },
    title: { textAlign: "center", textTransform: "uppercase" },
    content: {
      fontFamily: ultralightFont,
      textAlign: "center",
      fontSize: theme.typography.fontSize * 3,
      color: theme.palette.text.primary,
      marginBottom: theme.spacing(1),
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    loadingImage: {
      width: "100%",
      maxWidth: "500px",
      margin: "0 auto",
      padding: theme.spacing(4),
    },
  }),
);

function LoadingOverlay() {
  const {
    translations: { loadingOverlay: I18n },
  } = useContext(I18nContext);
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog open={isOpen} PaperProps={{ className: classes.container }} fullScreen={fullScreen}>
      <IconButton className={classes.closeButton} onClick={() => setIsOpen(false)} color="inherit" size="large">
        <Icon>close</Icon>
      </IconButton>
      <img src={preLoaderScreen} alt={I18n.preLoadingScreenImgAlt} className={classes.loadingImage} />
      <DialogContent>
        <Typography variant="h1" className={classes.content}>
          {I18n.generatingReportText}
        </Typography>
        <Typography variant="h1" className={classes.content}>
          {I18n.waitText}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

export default LoadingOverlay;
