import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.text.primary,
    textUnderlinePosition: "under",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export interface LinkProps extends React.ComponentPropsWithRef<typeof RouterLink> {
  to: string;
  className?: string;
  onClick?: React.AnchorHTMLAttributes<HTMLAnchorElement>["onClick"];
  target?: string;
  forwardedRef?: React.Ref<HTMLAnchorElement>;
}

const isHashFragment = (href: string) => href.match(/^#/);
export const isExternal = (href: string) => href.match(/^https?:\/\//);

export function Link(linkProps: LinkProps) {
  const { to, className, children, onClick, target, forwardedRef, ...props } = linkProps;

  const classes = useStyles();

  if (isHashFragment(to)) {
    return (
      <a className={classnames(classes.link, className)} href={to} onClick={onClick} target={target} ref={forwardedRef}>
        {children}
      </a>
    );
  }
  if (isExternal(to)) {
    return (
      <a
        className={classnames(classes.link, className)}
        href={to}
        target={target || "_blank"}
        rel="noopener noreferrer"
        onClick={onClick}
        ref={forwardedRef}
      >
        {children}
      </a>
    );
  }

  return (
    <RouterLink
      className={classnames(classes.link, className)}
      to={to}
      children={children}
      onClick={onClick}
      target={target}
      {...props}
      ref={forwardedRef}
    />
  );
}
export default React.forwardRef<any, LinkProps>((props, ref) => <Link {...props} forwardedRef={ref} />);
