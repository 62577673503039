import { useEffect, useState } from "react";

type Props = {
  children: React.ReactElement;
  periodInSeconds: number;
};

function WithDelay({ children, periodInSeconds }: Props) {
  const [isShown, setIsShown] = useState(false);

  const delay = periodInSeconds * 1000;

  useEffect(() => {
    const delayedComponentTimeout = setTimeout(() => {
      setIsShown(true);
    }, delay);

    return () => {
      clearTimeout(delayedComponentTimeout);
    };
  }, [delay]);

  return isShown ? children : null;
}

export default WithDelay;
