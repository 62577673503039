import { Container } from "@mui/material";
import React from "react";

function ContentContainer({ children }: React.PropsWithChildren<{}>) {
  return (
    <Container disableGutters maxWidth="lg">
      {children || false}
    </Container>
  );
}

export default ContentContainer;
