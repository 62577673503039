export interface RollbarService {
  userLoggedOut(): void;
  userLoggedIn(id: string, email: string, name: string): void;
  error(error: object, customData?: object): void;
}

export class ProductionRollbarService implements RollbarService {
  userLoggedOut() {
    // @ts-expect-error
    globalThis["Rollbar"].configure({ payload: { person: {} } });
  }

  userLoggedIn(id: string, email: string, name: string) {
    // @ts-expect-error
    globalThis["Rollbar"].configure({ payload: { person: { email, id, username: name } } });
  }

  error(error: object, customData?: object) {
    // @ts-expect-error
    globalThis["Rollbar"].error(error, customData);
  }
}

export class NullRollbarService implements RollbarService {
  userLoggedOut() {
    console.log("Track user log out");
  }

  userLoggedIn(id: string, email: string, name: string) {
    console.log("Track user log in", id, email, name);
  }

  error(error: object, customData?: object) {
    console.error("Track Error:", error, customData);
  }
}
