import { Button as MaterialButton, ButtonProps as MaterialButtonProps } from "@mui/material";
import React from "react";

export interface ButtonProps extends MaterialButtonProps {
  component?: React.ElementType;
}

export default function Button({ component = "button", ...props }: ButtonProps) {
  return <MaterialButton variant="outlined" component={component} {...props} />;
}
