import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import ButtonWithProgress from "shared/components/ButtonWithProgress";
import { CreateImpactReportEvent } from "shared/services/TrackingService";
import { measureDuration } from "shared/utilities/duration";
import { I18nContext } from "../I18nContext";
import trackingService from "../services/tracking";
import store from "../store";
import { black, white } from "../theme";

const fetchReport = async () => {
  const { duration } = await measureDuration(store.impactData.fetchReport());

  trackingService.track(
    new CreateImpactReportEvent({
      "Experiment Id": store.impactData.experimentId,
      "Event Ids": toJS(store.impactData.selectedAudienceEventIds),
      "Intervention Ids": toJS(store.impactData.selectedInterventionIds),
      "Load Duration Millis": duration,
      "Organization Id": store.impactData.organizationId || "",
    }),
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: black,
      color: white,
    },
  }),
);

function FetchReportButton() {
  const classes = useStyles();

  const {
    translations: { incrementalImpactReport: i18n },
  } = useContext(I18nContext);

  return (
    <Box mt={3} mb={3}>
      <ButtonWithProgress
        fullWidth
        disabled={store.impactData.requiredReportInputsSet}
        onClick={fetchReport}
        isRunning={store.impactData.dataLoading}
        className={classes.button}
      >
        <Typography variant="h5">{i18n.form.fetchReportButton}</Typography>
      </ButtonWithProgress>
    </Box>
  );
}

export default observer(FetchReportButton);
