import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { FieldProps } from "shared/components/form/FieldProps";
import Tooltip from "shared/components/Tooltip";

type Option = {
  value: string;
  label: string;
  disabledHint?: string;
};

export type MaterialRadioGroupProps = FieldProps<
  string,
  Omit<FormControlLabelProps, "onChange" | "value" | "control">
> & {
  value: string;
  options: Option[];
  onChange: (newValue: string) => void;
};

class MaterialRadioGroup extends React.Component<MaterialRadioGroupProps> {
  public render() {
    const { errors, options, value, disabled } = this.props;
    var optionsControls = options.map((option: Option) => {
      const { value, label, disabledHint } = option;

      const formLabel = (
        <FormControlLabel key={value} value={value} control={<Radio />} label={label} disabled={!!disabledHint} />
      );

      if (disabledHint) {
        return <Tooltip title={disabledHint}>{formLabel}</Tooltip>;
      }

      return formLabel;
    });

    return (
      <FormControl disabled={disabled}>
        <FormLabel component="legend">{this.props.label}</FormLabel>
        <RadioGroup value={value} onChange={this.onChange} row>
          {optionsControls}
        </RadioGroup>
        {errors.length > 0 && (
          <FormHelperText id={`${name}-error-text`}>{errors.map(error => error.detail).join(", ")}</FormHelperText>
        )}
      </FormControl>
    );
  }

  private onChange = (_event: React.ChangeEvent<HTMLInputElement>, selected: string) => {
    this.props.onChange(selected);
  };
}

export default MaterialRadioGroup;
