import { ImpactReportEventSummary } from "shared/http/apiTypes";
import { ILocaleFormatFunctions, TemplateTagFunction } from "shared/utilities/i18n";

function requireKeys<Keys extends string, Value = string>(value: { [Key in Keys]: Value }) {
  return value;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const en = (t: TemplateTagFunction, i18n: ILocaleFormatFunctions) => {
  return {
    common: {
      logout: t`Logout`,
    },
    remergeHomepage: "remerge.io",
    supportEmail: "support@remerge.io",
    notFound: {
      title: t`404 - PAGE NOT FOUND!`,
      content1: t`Unfortunately, we can't find the page you are looking for. Go back to the `,
      experimentSelection: t`experiment selection`,
      content2: t` or vist our `,
      homepage: t`homepage`,
    },

    errorOverlay: {
      title: t`Oh no! Something went wrong`,
      content: t`We have been automatically notified. In the meantime, you can try and reload the page, if the error still persists, try again a bit later or reach out to `,

      reloadButton: t`Reload`,
    },
    loadingOverlay: {
      generatingReportText: t`Generating report...`,
      waitText: t`Please wait.`,
      preLoadingScreenImgAlt: t`Preloading Screen`,
    },
    incrementalImpactReport: {
      title: t`Incremental Impact Report`,
      reportDetails: t`Report details`,
      footerImgAlt: t`Footer logo image`,
      header: {
        for: t`for`,
        shows: t`Shows`,
        eventsWithEffect: t`events with a relative effect of`,
        at: t`at`,
        perEventWithA: t`per event, with a`,
        confidenceRate: t`confidence rate`,
        negativeResult: t`Inconclusive results, possibly related to the technical setup or sample size. For more details, please refer to your account manager.`,
      },
      form: {
        audienceEvent: t`In-app event`,
        interventions: t`Interventions`,
        isRevenueEvent: t`Revenue event`,
        currency: t`Revenue currency`,
        noInterventionsError: t`No interventions are available for selected experiment`,
        fetchReportButton: t`Generate Report`,
        downloadReportButton: t`Download Report`,
        copyToClipboardButton: t`Copy to clipboard`,
        dateRange: t`Date Range`,
        audienceEventType: t`Event Type`,
      },
      valueTypes: {
        last: t`Last`,
        first: t`First`,
        mean: t`Mean`,
        high: t`High`,
        low: t`Low`,
      },
      scale: {
        above: "Above",
        percentage: {
          "90": "Very High",
          "89": "High",
          "60": "Neutral",
          "40": "Modest",
          "20": "Low",
        },
      },
      results: t`Results`,
      subSections: requireKeys<keyof ImpactReportEventSummary>({
        event: t`Event`,
        actual: t`Actual`,
        prediction: t`Prediction`,
        greater_than_zero_percent: t`> 0%`,
        impressions: t`Impressions`,
        clicks: t`Clicks`,
        win_rate: t`Win Rate`,
        advertiser_cost: t`Advertiser Cost`,
        incremental_events: t`Incremental events`,
        relative_effect: t`Relative effect`,
        advertiser_cost_per_inc: t`Advertiser's Cost Per Inc. Event`,
      }),
      sections: {
        keyResults: {
          title: t`Key results`,
          description: t`This graph shows the rate of the events happening within the selected duration. The incremental events figure shown here is the mean between actual events and predicted events.`,
          graph: {
            legend: {
              organic: t`Organic events`,
              incremental: t`Incremental events`,
            },
          },
        },
        costPerInc: {
          title: "Cost per incremental event",
          description: "The amount is calculated by dividing ad spend with the amount of incremental events.",
        },
        confidenceRate: {
          title: "Confidence rate",
          description:
            "The rate indicates the level of confidence that the intervention has made a positive impact on organic activity.",
        },
        additionalDetails: {
          title: "Additional details",
        },
        clickThroughRate: {
          title: "Click-through rate (CTR)",
          description:
            "Click-through rate is the number of clicks divided by the total number of impressions, multiplied by 100%.",
        },
        winRate: {
          title: t`Win Rate`,
          description:
            "The percentage measures the total number of impressions won divided by the amount of bid impressions, multiplied by 100%.",
        },
        spend: {
          title: "Ad spend",
          description: "The total amount of budget spent to generate incremental events.",
        },
        dataViz: {
          title: "Data visualisation",
          description:
            "This graph shows a more granular view of your campaign. Mouse over for the actual cumulative figures and specific timing.",
        },
        objective: {
          title: "Our objective",
          description:
            "We created the Incremental Impact Report dashboard for you to monitor how your campaign budget is being spent on tests and the level of impact they have made on in-app events. For technical purposes, some of the information shown above are mean figures. If you have any enquiries, please contact your account manager.",
        },
      },
      legends: {
        installs: "Incremental events",
        ad_spend: t`Ad spend`,
      },
    },
    organizations: {
      title: "Select Organization",
      breadcrumb: "Organizations",
    },
    graph: {
      interventionStarted: "Intervention started",
      axisLeft: "Events",
      tooltip: {
        result: t`Result`,
        prediction: t`Prediction`,
        cumulative: t`Cumulative`,
      },
      legend: {
        organic: "Predicted organic events",
        measured: "Measured events",
        interval: "Credible interval",
      },
    },
    notApplicable: t`N/A`,
    genericError: t`Something went wrong and this has been to notified to remerge team. If you need help contact your account manager`,
    audienceEventTypes: {
      eur: "EUR",
      usd: "USD",
      count: "Count",
    },
    disabledBecauseEventDoesNotSupportRevenue: (eventNames: string[]) =>
      t`The follwing events do not support revenue based reports: ${eventNames.join(", ")}`,
  };
};

export default en;
