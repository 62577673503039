export interface ISelectOption {
  label: string;
  value: string | number | { id: string };
  link?: string;
  disabled?: boolean;
}

export function optionKey(value: ISelectOption["value"]): string | number {
  if (value && typeof value === "object") {
    return value.id || "";
  }
  return value;
}
