import { Avatar, List, ListItem, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { OrganizationsIndexResource } from "shared/http/apiTypes";
import { Link } from "wouter";
import ErrorOverlay from "../../components/ErrorOverlay";
import PageContainer from "../../components/PageContainer";
import store from "../../store";
import { I18nContext } from "./../../I18nContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    "@global": {
      "[href]": {
        cursor: "pointer",
      },
    },
    navigation: {
      "& li:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    orgAvatar: { display: "inline-block", marginRight: 8 },
  }),
);

function OrganizationsIndex() {
  useEffect(() => {
    store.organizations.fetchOrganizations();
  }, []);

  const {
    translations: { organizations: i18n },
  } = useContext(I18nContext);
  const classes = useStyles();

  if (store.organizations.dataLoading) return <b>Loading...</b>;

  return (
    <PageContainer>
      <h1>{i18n.title}</h1>

      <List>
        {Object.values(store.organizations.organizations).map((organization: OrganizationsIndexResource) => (
          <>
            <h2 style={{ display: "flex", alignItems: "center" }}>
              <Avatar className={classes.orgAvatar} src={organization.attributes.logo}></Avatar>
              {organization.attributes.name}
            </h2>

            <List className={classes.navigation}>
              {(store.organizations.experimentsByOrg[organization.id] || []).map(experiment => (
                <ListItem>
                  <Link href={`/organizations/${organization.id}/experiment/${experiment.id}`} key={organization.id}>
                    <ListItemText>{experiment.attributes.name}</ListItemText>
                  </Link>
                </ListItem>
              ))}
            </List>
            <hr />
          </>
        ))}
      </List>
      {store.organizations.hasErrors() && <ErrorOverlay />}
    </PageContainer>
  );
}

export default observer(OrganizationsIndex);
