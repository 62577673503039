import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import locale from "locale2";
import moment from "moment";
import RemergeApi from "shared/http/RemergeApi";
import { parseIdToken, removeIdToken } from "shared/http/id-token";
import HttpService from "shared/services/HttpService";
import { AuthenticatedContent } from "./AuthenticatedContent";
import { I18nProvider, i18n } from "./I18nContext";
import "./index.css";
import rollbarService from "./services/rollbar";
import trackingService from "./services/tracking";

moment.locale(locale);

function App() {
  const idToken = parseIdToken();
  if (!idToken || !idToken.user.id) {
    window.location.href = `${import.meta.env.VITE_LOGIN_URL}?redirectTo=${encodeURIComponent(document.location.href)}`;
    removeIdToken();
    rollbarService.userLoggedOut();
    trackingService.logout();
    return null;
  }

  const { id, email, name } = idToken.user;

  rollbarService.userLoggedIn(id, email, name);
  trackingService.identify(id.toString(), name);

  const httpService = new HttpService(import.meta.env.VITE_API_HOST as string);
  const remergeApi = new RemergeApi(httpService);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <I18nProvider value={i18n}>
        <AuthenticatedContent idToken={idToken} remergeApi={remergeApi} />
      </I18nProvider>
    </LocalizationProvider>
  );
}

export default App;
