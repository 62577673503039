import { Box, Link, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { Link as RouterLink } from "wouter";
import { I18nContext } from "../I18nContext";
import store from "../store";
import ContentContainer from "./ContentContainer";

function OrganizationBreadcrumb() {
  const { translations } = useContext(I18nContext);

  const {
    organizations: { organizations, experimentsByOrg },
    impactData: { organizationId, experimentId },
  } = store;

  const selectedOrg = organizations[organizationId];

  if (!selectedOrg) return null;

  const selectedOrgExperiments = experimentsByOrg[selectedOrg.id] || [];
  const selectedExperiment = selectedOrgExperiments.find(experiment => experiment.id === experimentId);

  return (
    <ContentContainer>
      <Box p={2}>
        <Typography variant="h6" color="inherit">
          <RouterLink to="/organizations">
            <Link>{translations.organizations.breadcrumb}</Link>
          </RouterLink>{" "}
          {">"} {selectedOrg.attributes.name}
          {selectedExperiment ? ` > ${selectedExperiment.attributes.name}` : null}
        </Typography>
      </Box>
    </ContentContainer>
  );
}

export default observer(OrganizationBreadcrumb);
