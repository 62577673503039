import { IconButton, IconButtonProps } from "@mui/material";
import React from "react";
import Link, { LinkProps } from "./Link";

type LegacyIconButtonLinkProps = Omit<IconButtonProps, "ref"> &
  Pick<LinkProps, "to" | "target" | "onClick"> & {
    key?: string | number | undefined;
    onClick?: React.AnchorHTMLAttributes<HTMLAnchorElement>["onClick"];
    ref?: React.Ref<HTMLAnchorElement>;
  };

function LegacyIconButtonLink({ children, to, target, onClick, ...iconButtonProps }: LegacyIconButtonLinkProps) {
  const LinkWrapper = React.forwardRef<HTMLAnchorElement, IconButtonProps>(({ children, className }, ref) => (
    <Link to={to} className={className} onClick={onClick} target={target} ref={ref}>
      {children}
    </Link>
  ));

  return (
    <IconButton component={LinkWrapper} {...iconButtonProps} size="large">
      {children}
    </IconButton>
  );
}

export default LegacyIconButtonLink;
