export type ResourceId = string;
export type ResourceType = string;

export type Document<Resource extends PersistedResourceObject<any, any>> =
  | IDocumentWithData<Resource>
  | IDocumentWithErrors;
export interface IDocumentWithData<Resource extends PersistedResourceObject<any, any>> {
  data: Data<Resource>;
  meta?: object;
}

export interface IDocumentWithErrors {
  errors: IErrorObject[];
  meta?: Record<string, any>;
}

export interface RelatedResourceSource extends Object {
  resource: { id: ResourceId | null; type: ResourceType };
  attribute?: string;
}

export interface PrimaryResourceSource extends Object {
  pointer: string;
  parameter?: string;
}

export function isErrorInPrimaryResource(
  source: RelatedResourceSource | PrimaryResourceSource,
): source is PrimaryResourceSource {
  return source.hasOwnProperty("pointer");
}

export function isErrorInRelatedResource(
  source: RelatedResourceSource | PrimaryResourceSource,
): source is RelatedResourceSource {
  return source.hasOwnProperty("resource");
}

export interface IErrorObject {
  id?: number | string;
  code?: string; // Used only in BillingAccountsController#send_statement
  detail: string;
  source: RelatedResourceSource | PrimaryResourceSource;
}

export type Data<Resource extends PersistedResourceObject<any, any>> = Resource[] | Resource;

export interface ICollectionDocument<Resource extends PersistedResourceObject<any, any>> {
  data: Resource[];
}

export interface ICollectionDocumentWithMeta<Resource extends PersistedResourceObject<any, any>, Meta extends object>
  extends ICollectionDocument<Resource> {
  meta: Meta;
}

export interface ISingularDocument<Resource extends UnpersistedResourceObject<any, any>> {
  data: Resource;
  meta?: object;
}

export type PersistedResourceObject<
  Attributes extends object,
  Relationships extends RelationshipsObject = undefined,
> = UnpersistedResourceObject<Attributes, Relationships> & { id: ResourceId };

export type UnpersistedResourceObject<
  Attributes extends object,
  Relationships extends RelationshipsObject = undefined,
> = {
  type: ResourceType;
  attributes: Attributes;
} & (Relationships extends undefined ? {} : { relationships: Relationships });

export type RelationshipsObject = undefined | { [Key: string]: HasOneRelationship | HasManyRelationship | undefined };

export interface ResourceIdentifier {
  id: ResourceId;
  type: ResourceType;
}

export interface HasOneRelationship {
  data: ResourceIdentifier | null;
}

export interface HasOneRelationshipRequired {
  data: ResourceIdentifier;
}

export interface HasManyRelationship {
  data: ResourceIdentifier[];
}

export function isBatchUpdateFailure<Resource extends PersistedResourceObject<any, any>>(
  batchUpdateResult: BatchUpdateResult<Resource>,
): batchUpdateResult is BatchUpdateFailure {
  return batchUpdateResult.status === 422;
}

type BatchUpdateSuccess<Resource extends PersistedResourceObject<any, any>> = {
  status: 200;
  data: Resource;
};

export type BatchUpdateFailure = {
  status: 422;
  meta: { id: ResourceId };
  body: { errors: IErrorObject[] };
};

type BatchUpdateResult<Resource extends PersistedResourceObject<any, any>> =
  | BatchUpdateSuccess<Resource>
  | BatchUpdateFailure;

export type BatchUpdateResponse<Resource extends PersistedResourceObject<any, any>> = BatchUpdateResult<Resource>[];

export type BatchUpdateRequest<
  Attributes extends Object,
  Relationships extends RelationshipsObject = undefined,
> = PersistedResourceObject<Attributes, Relationships>[];

export type BatchCreateRequest<
  Attributes extends Object,
  Relationships extends RelationshipsObject = undefined,
> = UnpersistedResourceObject<Attributes, Relationships>[];

export type BatchCreateSuccess<Resource extends PersistedResourceObject<any, any>> = {
  status: 201;
  body: { data: Resource };
};

export type BatchCreateFailure = {
  status: 422;
  body: { errors: IErrorObject[] };
};

export type BatchCreateResult<Resource extends PersistedResourceObject<any, any>> =
  | BatchCreateSuccess<Resource>
  | BatchCreateFailure;

export type BatchCreateResponse<
  Attributes extends object,
  Relationships extends RelationshipsObject = undefined,
> = BatchCreateResult<PersistedResourceObject<Attributes, Relationships>>[];

export type BatchCreateSwrResponse<
  Attributes extends object,
  Relationships extends RelationshipsObject,
> = BatchCreateResult<PersistedResourceObject<Attributes, Relationships>>[];

export function isBatchCreateSuccess<Resource extends PersistedResourceObject<any, any>>(
  batchCreateResult: BatchCreateResult<Resource>,
): batchCreateResult is BatchCreateSuccess<Resource> {
  return batchCreateResult.status === 201;
}

export function isBatchCreateFailure<Resource extends PersistedResourceObject<any, any>>(
  batchCreateResult: BatchCreateResult<Resource>,
): batchCreateResult is BatchCreateFailure {
  return batchCreateResult.status === 422;
}

export function isBatchDeleteFailure(batchDeleteResult: BatchDeleteResult): batchDeleteResult is BatchDeleteFailure {
  return batchDeleteResult.status === 422;
}

export type BatchDeleteSuccess = {
  id: ResourceId;
  status: 200;
};

export type BatchDeleteFailure = {
  status: 422;
  meta: { id: ResourceId };
  body: { errors: IErrorObject[] };
};

export type BatchDeleteResult = BatchDeleteSuccess | BatchUpdateFailure;

export type BatchDeleteResponse = {
  results: BatchDeleteResult[];
  deleted_rows: number;
  not_deleted_rows: number;
};

export type BatchDeleteRequest = {
  ids: ResourceId[];
};

export function data<ResultType>(promise: Promise<{ data: ResultType }>): Promise<ResultType> {
  return promise.then(({ data }) => data);
}

export type JSONValue = string | number | boolean | null | JSONArray | JSONObject;

interface JSONArray extends Array<JSONValue> {}

export interface JSONObject {
  [x: string]: JSONValue;
}
