import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "shared/components/LegacyButton";
import { black, white } from "../theme";

interface HeaderButtonProps {
  children: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: black,
      borderColor: black,
      padding: theme.spacing(2, 4),
      "&:hover": {
        color: white,
        backgroundColor: black,
      },
    },
  }),
);

function HeaderButton({ children, onClick }: HeaderButtonProps) {
  const classes = useStyles();

  return (
    <Box m={2}>
      <Button onClick={onClick} className={classes.button} variant="outlined">
        <Typography variant="h5">{children}</Typography>
      </Button>
    </Box>
  );
}

export default HeaderButton;
