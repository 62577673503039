import SearchIcon from "@mui/icons-material/Search";
import { ListSubheader, MenuItem, MenuItemProps, Paper, Popper, styled } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import { GetInputPropsOptions, GetItemPropsOptions, GetMenuPropsOptions, GetPropsCommonOptions } from "downshift";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import React from "react";
import { List, ListRowProps } from "react-virtualized";
import Checkbox from "shared/components/Checkbox";
import { ISelectOption, optionKey } from "shared/components/ISelectOption";
import TextField from "shared/components/TextField";
import { black, lightGreyBorder } from "../../utilities/theme";
import { selectAllOption } from "./MaterialAutocompleteMultiple";

export function equalValue(a: ISelectOption, b: ISelectOption): boolean {
  return isEqual(a.value, b.value);
}

export const PROGRESS_SIZE = 16;

interface IRenderOptionProps {
  option: ISelectOption;
  itemProps: Omit<MenuItemProps, "button">;
  isHighlighted: boolean;
  selectedItem: ISelectOption | ISelectOption[] | null | undefined;
  style: React.CSSProperties;
  withCheckbox?: boolean;
  allSelected?: boolean;
}

export function removeDuplicateValues(options: ISelectOption[]) {
  const uniqueOptions = uniqWith(options, equalValue);
  if (uniqueOptions.length !== options.length) {
    console.error(new Error("Autocomplete given options with duplicates in their values"));
  }
  return uniqueOptions;
}

export const itemToString = (option: ISelectOption | undefined) => (option ? option.label : "");

export const renderOption = ({
  option,
  itemProps,
  isHighlighted,
  selectedItem,
  style,
  withCheckbox = false,
  allSelected = false,
}: IRenderOptionProps) => {
  const isSelected = Array.isArray(selectedItem)
    ? selectedItem.some(item => item.value === option.value)
    : (selectedItem || "").toString().indexOf(option.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      dense
      key={optionKey(option.value)}
      selected={isHighlighted}
      style={{
        fontWeight: isSelected ? 500 : 400,
        overflowY: "hidden",
        ...style,
      }}
      disabled={option.disabled}
      onClick={itemProps.onClick}
    >
      {withCheckbox ? (
        <Checkbox
          preventDefaultClick
          label={option.label}
          value={isSelected || (option.value === selectAllOption.value && allSelected)}
        />
      ) : (
        option.label
      )}
    </MenuItem>
  );
};

interface IGetRowRendererProps extends Pick<IRenderOptionProps, "selectedItem"> {
  options: ISelectOption[];
  highlightedIndex: number | null;
  itemClass: string;
  getItemProps(options: GetItemPropsOptions<ISelectOption>): any;
  withCheckbox?: boolean;
  allSelected?: boolean;
}

export const getRowRenderer = ({
  options,
  highlightedIndex,
  selectedItem,
  getItemProps,
  itemClass,
  withCheckbox = false,
  allSelected = false,
}: IGetRowRendererProps) => {
  return (rowProps: ListRowProps) => {
    const option = options[rowProps.index];
    const isHighlighted = rowProps.index === highlightedIndex;
    return renderOption({
      isHighlighted,
      option,
      selectedItem,
      itemProps: getItemProps({ index: rowProps.index, item: option, className: itemClass }),
      style: rowProps.style as React.CSSProperties,
      withCheckbox,
      allSelected,
    });
  };
};

export const getOptions = ({
  query,
  options: allOptions,
  selectedValues = [],
  showOptionsOnFocus = false,
  removeSelected = false,
  includeSelectAll = false,
}: {
  query: string;
  options: ISelectOption[];
  selectedValues: ISelectOption["value"][];
  showOptionsOnFocus?: boolean;
  removeSelected?: boolean;
  includeSelectAll?: boolean;
}) => {
  const options = removeDuplicateValues(allOptions);

  const notSelectedOptions = options.filter(option => !selectedValues.map(optionKey).includes(optionKey(option.value)));

  if (showOptionsOnFocus && options.some(option => option.label === query)) return notSelectedOptions;

  const suggestedOptions = removeSelected
    ? notSelectedOptions.filter(option => filterOptionByQuery(option, query))
    : options.filter(option => filterOptionByQuery(option, query));

  if (!query && includeSelectAll && suggestedOptions.length > 0) return [selectAllOption, ...suggestedOptions];

  return suggestedOptions;
};

export const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: "inline-block",
      position: "relative",
    },
    fullWidthContainer: {
      position: "relative",
      width: "100%",
    },

    selectedImgUrl: {
      width: 50,
      height: 50,
      marginRight: theme.spacing(1),
    },

    selectedImg: {
      objectFit: "contain",
    },

    progressContainer: {
      paddingTop: (30 - PROGRESS_SIZE) / 2,
      paddingRight: theme.spacing(1 / 2),
    },
  });

const optionMenuStyles = (theme: Theme) =>
  createStyles({
    popper: {
      zIndex: theme.zIndex.tooltip,
    },
    menuItem: {
      boxSizing: "border-box",
      transition: "none",
    },
  });

const SearchField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    borderRadius: "4px",
    paddingLeft: 0,

    "&.Mui-focused fieldset": {
      border: `1px solid black`,
    },
  },

  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },

  "& .MuiSvgIcon-root": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    fontSize: 22,
    color: black,
    transition: "color 0.2s ease-in-out",
  },

  "& .Mui-focused .MuiSvgIcon-root": {
    color: lightGreyBorder,
  },
}));

type WithCheckboxProps = {
  withCheckbox: true;
  getInputProps: (options: GetInputPropsOptions | undefined) => any;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchQuery: string;
  allSelected: boolean;
  searchInputRef: React.RefObject<HTMLInputElement>;
};
type CheckboxProps = WithCheckboxProps | { withCheckbox: false };
type MenuProps = {
  options: ISelectOption[];
  isOpen: boolean;
  anchorNode: HTMLDivElement | null | undefined;
  rowCount: number;
  rowHeight: number;
  highlightedIndex: number | null;
  selectedItemKey: ISelectOption | ISelectOption[] | null | undefined;
  getMenuProps: (options?: GetMenuPropsOptions, otherOptions?: GetPropsCommonOptions) => any;
  getItemProps: (options: GetItemPropsOptions<any>) => any;
  checkBoxProps?: CheckboxProps;
  withSearchBar?: boolean;
};

function StyledOptionMenu({
  classes,
  options,
  isOpen,
  anchorNode,
  rowCount,
  rowHeight,
  highlightedIndex,
  selectedItemKey,
  getMenuProps,
  getItemProps,
  withSearchBar = false,
  checkBoxProps = { withCheckbox: false },
}: MenuProps & WithStyles<typeof optionMenuStyles>) {
  const { withCheckbox } = checkBoxProps;

  const totalHeight = rowCount * rowHeight;
  const menuHeight = totalHeight > 300 ? 300 : totalHeight;
  return (
    <Popper
      className={classes.popper}
      open={isOpen}
      anchorEl={anchorNode}
      placement="bottom-start"
      modifiers={[
        {
          name: "flip",
          enabled: true,
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            boundariesElement: "window",
          },
        },
      ]}
    >
      <Paper
        square
        {...(isOpen ? getMenuProps({ height: menuHeight }, { suppressRefError: true }) : {})}
        style={{ width: anchorNode ? anchorNode.parentElement?.clientWidth : undefined }}
      >
        {withSearchBar && renderSearchBar(checkBoxProps as WithCheckboxProps)}

        <List
          tabIndex={-1}
          autoWidth
          autoContainerWidth
          width={1000}
          height={menuHeight}
          rowHeight={rowHeight}
          rowCount={rowCount}
          scrollToIndex={highlightedIndex === null ? undefined : highlightedIndex}
          rowRenderer={getRowRenderer({
            options,
            getItemProps,
            highlightedIndex,
            itemClass: classes.menuItem,
            selectedItem: selectedItemKey,
            withCheckbox,
            allSelected: withCheckbox ? checkBoxProps.allSelected : false,
          })}
        />
      </Paper>
    </Popper>
  );
}

function renderSearchBar({ onSearch, getInputProps, searchInputRef, searchQuery }: WithCheckboxProps) {
  return (
    <ListSubheader>
      <SearchField
        size="small"
        placeholder="Type to search..."
        fullWidth
        inputRef={searchInputRef}
        InputProps={{
          startAdornment: <SearchIcon />,
          ...getInputProps({
            onChange: onSearch,
            value: searchQuery,
            onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === "Tab") event.preventDefault();
            },
          }),
        }}
      />
    </ListSubheader>
  );
}
function filterOptionByQuery(option: ISelectOption, query: string): boolean {
  return !query || option.label.toLowerCase().indexOf(query.toLowerCase()) !== -1;
}

export const OptionMenu = withStyles(optionMenuStyles)(StyledOptionMenu);
