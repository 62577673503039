import { FormHelperText } from "@mui/material";
import ReactMarkdown from "react-markdown";

import { FieldError } from "shared/utilities/form/FormState";

interface FieldErrorsProps {
  errors: FieldError[];
}

function FieldErrors({ errors }: FieldErrorsProps) {
  return (
    <FormHelperText>
      <ReactMarkdown linkTarget="_blank">{errors.map(fieldError => fieldError.detail).join(", ")}</ReactMarkdown>
    </FormHelperText>
  );
}

export default FieldErrors;
