import { TextField as MaterialTextField, OutlinedTextFieldProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { WithStyles } from "@mui/styles";
import createStyles from "@mui/styles/createStyles";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 5,
    },
  });

export interface TextFieldProps extends Omit<OutlinedTextFieldProps, "variant"> {
  variant?: "filled" | "standard" | "outlined";
}

function TextField({ className, classes, variant, ...restProps }: TextFieldProps & WithStyles<typeof styles>) {
  return (
    <MaterialTextField {...restProps} className={classNames(classes.root, className)} variant={variant || "outlined"} />
  );
}

export default withStyles(styles)(TextField);
