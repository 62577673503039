import { IErrorObject, isErrorInPrimaryResource } from "shared/http/jsonApi";

function assignErrorPath(root: object, path: string[], value: any): void {
  const nextPathSegment = path[0];
  if (path.length === 1) {
    // @ts-expect-error
    root[nextPathSegment] = root[nextPathSegment] ? root[nextPathSegment].concat(value) : [value];
  } else {
    // @ts-expect-error
    if (root[nextPathSegment] === undefined) {
      // @ts-expect-error
      root[nextPathSegment] = {};
    }
    // @ts-expect-error
    const nextObject = root[nextPathSegment];
    const pathTail = path.slice(1);
    assignErrorPath(nextObject, pathTail, value);
  }
}

export function stripRootPath(pathString: string, rootPath: string) {
  if (pathString.indexOf(rootPath) !== 0) {
    throw new Error(`Path '${pathString}' does not begin with expected root string '${rootPath}'`);
  }
  return pathString.slice(rootPath.length);
}

export type ErrorsObject = { [Key: string]: ErrorsObject | IErrorObject[] };

export default function jsonApiErrorsToObject(jsonApiErrors: IErrorObject[], rootPath?: string): ErrorsObject {
  const errorsObject = {};
  jsonApiErrors.forEach(errorObject => {
    if (errorObject.source && isErrorInPrimaryResource(errorObject.source) && errorObject.detail) {
      const jsonPointer = errorObject.source.pointer;
      const pathString = rootPath ? stripRootPath(jsonPointer, rootPath) : jsonPointer;
      const errorPath = pathString.split("/").slice(1);
      assignErrorPath(errorsObject, errorPath, errorObject);
    }
  });
  return errorsObject;
}
