import { CssBaseline, Grid } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { useEffect } from "react";
import RemergeApi from "shared/http/RemergeApi";
import { IdToken, removeIdToken } from "shared/http/id-token";
import { Redirect, Route, Switch } from "wouter";
import { NotFound } from "./components/NotFoundPage";
import TopBar from "./components/TopBar";
import IncrementalImpactReportIndex from "./incrementalImpactReport/index/IncrementalImpactReportIndex";
import OrganizationsIndex from "./organizations/index/OrganizationsIndex";
import trackingService from "./services/tracking";
import theme from "./theme";

interface AuthenticatedContentProps {
  idToken: IdToken;
  remergeApi: RemergeApi;
}

function logOut() {
  removeIdToken();
  window.location.href = `${import.meta.env.VITE_LOGIN_URL}?redirectTo=${document.location.href}`;
}

export function AuthenticatedContent({ idToken, remergeApi }: AuthenticatedContentProps) {
  useEffect(() => {
    if (idToken.user.admin) {
      trackingService.setUserInfo({
        employee_region: idToken.user.employee_region,
        employee_type: idToken.user.employee_type,
      });
    }
  }, [idToken]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Grid container direction="column" alignContent="flex-start" wrap="nowrap">
          <TopBar logOut={logOut} userName={idToken.user.name} />
          <Switch>
            <Route path="/organizations/:organizationId/experiment/:experimentId">
              {params => (
                <IncrementalImpactReportIndex
                  remergeApi={remergeApi}
                  organizationId={params.organizationId}
                  experimentId={params.experimentId}
                />
              )}
            </Route>
            <Route path="/organizations">
              <OrganizationsIndex />
            </Route>
            <Route path="/">
              <Redirect to="/organizations" />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Grid>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
