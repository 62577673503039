import { FormControl } from "@mui/material";
import React from "react";
import {
  IMaterialAutocompleteMultipleProps,
  MaterialAutocompleteMultiple,
} from "shared/components/MaterialAutocomplete";
import { FieldProps } from "shared/components/form/FieldProps";
import FieldErrors from "./FieldErrors";

interface MaterialAutocompleteMultipleFieldProps<ValueType>
  extends Omit<
    FieldProps<ValueType[], IMaterialAutocompleteMultipleProps>,
    "values" | "defaultValue" | "onBlur" | "onFocus"
  > {
  value: ValueType[];
  variant?: "filled" | "standard" | "outlined" | undefined;
}

export default class MaterialAutocompleteMultipleField<ValueElementType> extends React.Component<
  MaterialAutocompleteMultipleFieldProps<ValueElementType>
> {
  public render() {
    const {
      id,
      value,
      label,
      errors,
      options,
      placeholder,
      onChange,
      disabled,
      fullWidth,
      autoFocus,
      variant,
      endAdornment,
      inputTitle,
      required,
      ...formControlProps
    } = this.props;
    const hasErrors = errors.length > 0;

    return (
      <FormControl disabled={disabled} error={hasErrors} fullWidth={fullWidth} {...formControlProps}>
        <MaterialAutocompleteMultiple
          id={id}
          values={value}
          onChange={onChange}
          options={options}
          label={label}
          placeholder={placeholder}
          disabled={disabled}
          fullWidth={fullWidth}
          autoFocus={autoFocus}
          variant={variant}
          endAdornment={endAdornment}
          error={hasErrors}
          inputTitle={inputTitle}
          required={required}
        />
        {hasErrors && <FieldErrors errors={errors} />}
      </FormControl>
    );
  }
}
