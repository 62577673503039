import { CartesianMarkerProps } from "@nivo/core";
import { Datum, DatumValue, Layer, ResponsiveLine, SliceTooltipProps } from "@nivo/line";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import { black } from "../../theme";
import { MinMaxValues } from "../../utilities/impactData";
import ImpactGraphTooltip from "./ImpactGraphTooltip";
import PredictionAreaLayer from "./PredictionAreaLayer";
import StyledLines from "./StyledLines";

export const GRAPH_HEIGHT = 600;

const getRenderLayers = (props: ImpactGraphProps) => {
  const { showPredictionArea = true, areaSeriesId } = props;

  const renderLayers: Layer[] = ["grid", "areas"];

  if (showPredictionArea && areaSeriesId) {
    renderLayers.push(props => <PredictionAreaLayer {...props} areaSeriesId={areaSeriesId} />);
  }

  return renderLayers.concat([StyledLines, "slices", "axes", "points", "legends", "markers"]);
};

export interface ImpactGraphProps {
  data: { id: string; data: Datum[] }[];
  interventionStart?: DatumValue | null | undefined;
  showPredictionArea?: boolean;
  yLimits: MinMaxValues;
  areaSeriesId: string;
}

function ImpactGraph(props: ImpactGraphProps) {
  const { data, interventionStart, yLimits = {} } = props;

  const {
    translations: { graph },
  } = useContext(I18nContext);

  const markers: CartesianMarkerProps[] = interventionStart
    ? [
        {
          axis: "x",
          value: new Date(interventionStart || ""),
          lineStyle: { strokeDasharray: "10,10", stroke: black, strokeWidth: 2 },
          legend: graph.interventionStarted,
        },
      ]
    : [];

  return (
    <div style={{ height: GRAPH_HEIGHT }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 90, right: 0, bottom: 50, left: 60 }}
        curve="monotoneX"
        xScale={{
          type: "time",
          format: "%Y-%m-%d %I:%M:%S",
          useUTC: true,
          precision: "hour",
        }}
        xFormat="time:%Y-%m-%d"
        yScale={{ type: "linear", ...yLimits }}
        axisLeft={{
          legend: graph.axisLeft,
          legendOffset: -50,
          legendPosition: "middle",
        }}
        enablePoints={false}
        layers={getRenderLayers(props)}
        axisBottom={{
          format: "%b %d",
          tickValues: "every 1 days",
          legendOffset: -12,
        }}
        markers={markers}
        enableSlices="x"
        sliceTooltip={(sliceProps: SliceTooltipProps) => <ImpactGraphTooltip {...sliceProps} />}
      />
    </div>
  );
}

export default ImpactGraph;
