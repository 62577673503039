import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useContext } from "react";
import { I18nContext } from "../../I18nContext";
import { black, importantValueFontSize, lightGrey } from "../../theme";

type Props = {
  value: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      width: "auto",
    },
    barContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        justifyContent: "flex-start",
      },
    },
    title: {
      marginBottom: theme.spacing(4),
      fontSize: importantValueFontSize,
    },
    percentageBar: {
      alignItems: "stretch",
      backgroundColor: lightGrey,
      display: "flex",
      flexDirection: "column",
      height: 150,
      justifyContent: "flex-end",
      margin: theme.spacing(0, 1),
      width: 12,
    },
    percentageFill: {
      backgroundColor: black,
      display: "flex",
    },
    scale: {
      display: "flex",
      height: 150,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
  }),
);

export const getValueLabel = (value: number, I18n: Record<string, string>) => {
  if (value < 20) return I18n["20"];
  if (value >= 90) return I18n["90"];

  const highestPercent =
    Object.keys(I18n)
      .map(key => Number(key))
      .find(bucketNumber => bucketNumber >= value) || 0;

  const highestKey = highestPercent.toString();

  if (!highestPercent || !I18n[highestKey]) return "";

  return I18n[highestKey];
};

function ScaleValue({ value }: Props) {
  const classes = useStyles();
  const {
    translations: { incrementalImpactReport: I18n },
  } = useContext(I18nContext);

  const scaleSteps = [
    `${I18n.scale.above} 90% = ${I18n.scale.percentage["90"]}`,
    `61% - 89% = ${I18n.scale.percentage["89"]}`,
    `41% - 60% = ${I18n.scale.percentage["60"]}`,
    `21% - 40% = ${I18n.scale.percentage["40"]}`,
    `0% - 20% = ${I18n.scale.percentage["20"]}`,
  ];

  const valueLabel = getValueLabel(value, I18n.scale.percentage);

  return (
    <Grid className={classes.root} direction="column">
      <Typography className={classes.title} variant="h3">
        {`${valueLabel} (${value.toFixed(0)}%)`}
      </Typography>

      <Grid className={classes.barContainer}>
        <div className={classes.percentageBar}>
          <div className={classes.percentageFill} style={{ flex: value / 100 }}></div>
        </div>
        <div className={classes.scale}>
          {scaleSteps.map(stepText => (
            <div key={stepText}> - {stepText}</div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
}
export default ScaleValue;
