import { Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

type Props = {
  title?: string;
  sectionNumber?: number;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 40,
    },
  }),
);

function PageSection({ title, sectionNumber }: Props) {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item md={1} xs={2}>
        {sectionNumber && (
          <Typography className={classes.title} variant="h4">
            {sectionNumber.toString().padStart(2, "0")}
          </Typography>
        )}
      </Grid>
      <Grid item md={11} xs={10}>
        {title && (
          <Typography className={classes.title} variant="h4">
            {title}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default PageSection;
