import { Box, Grid, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import React from "react";
import { importantValueFontSize, olive, subHeadlineFontSize } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4, 0),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(0, 0, 4, 0),
        textAlign: "left",
      },
    },
    spaced: {
      marginBottom: theme.spacing(2),
    },
    value: {
      fontSize: importantValueFontSize,
    },
    bottomAlignContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      marginBottom: theme.spacing(2),
    },
    description: {
      color: olive.dark,
    },
    title: {
      fontSize: subHeadlineFontSize,
    },
    extraDataCell: {
      borderBottom: 0,
    },
    extraDataText: {
      fontSize: 14,
    },
  }),
);

export type Props = {
  title: string;
  value: string;
  subtitle?: string;
  description?: string;
  extraData?: { [label: string]: string | number };
  children?: React.ReactNode;
};

function ImportantValue({ title, subtitle, description, value, extraData, children }: Props) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {description && (
        <Typography className={classnames(classes.description, classes.spaced)}>{description}</Typography>
      )}
      <Grid container>
        <Grid item xs={12} sm={extraData ? 8 : 12} className={classes.bottomAlignContent}>
          <Typography className={classnames(classes.title, classes.spaced)} variant="h6">
            {title}
          </Typography>
          <Typography className={classnames({ [classes.spaced]: !!extraData }, classes.value)} variant="h3">
            {value}
          </Typography>
          {subtitle && <Typography variant="h6">{subtitle}</Typography>}
        </Grid>
        {extraData ? (
          <Grid item xs={12} sm={extraData ? 4 : false} className={classes.bottomAlignContent}>
            <Table size="small" padding="none">
              <TableBody>
                {Object.entries(extraData).map(([dataKey, dataValue]) => (
                  <TableRow key={dataKey}>
                    <TableCell className={classes.extraDataCell}>
                      <Typography className={classes.extraDataText}>{dataKey}</Typography>
                    </TableCell>
                    <TableCell className={classes.extraDataCell}>
                      <Typography className={classes.extraDataText}>{dataValue}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        ) : null}
        {children && (
          <Grid item xs={12}>
            {children}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ImportantValue;
